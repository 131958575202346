<template>
  <div class="personal-info">
    <h2>个人信息</h2>
    <div class="user-actions">
      <span v-if="loggedIn">{{ user.username }}</span>
      <router-link v-if="!loggedIn" to="/login">登录</router-link>
      <router-link v-if="!loggedIn" to="/register">注册</router-link>
      <button v-if="loggedIn" @click="logout">注销</button>
    </div>
    <!-- 其他个人信息内容 -->
    <div v-if="loggedIn">
      <p><strong>用户名:</strong> {{ user.username }}</p>
      <p><strong>手机号:</strong> {{ user.phone }}</p>
    </div>
    <div v-else>
      <p>请先登录以查看个人信息。</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: null
    };
  },
  computed: {
    loggedIn() {
      return !!this.user;
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.user = null; // 重置用户数据
      this.$router.push('/login');
    },
    getUserFromLocalStorage() {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          return JSON.parse(userData);
        } catch (e) {
          console.error("无法解析用户数据:", e);
          localStorage.removeItem('user'); // 清除错误数据
        }
      }
      return null;
    },
    checkAuth() {
      if (!this.loggedIn) {
        this.$router.push('/login');
      }
    },
    created() {
      this.user = this.getUserFromLocalStorage();
    }
  }
};
</script>

<style>
.personal-info {
  position: relative;
  padding: 20px;
}

.user-actions {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 10px;
}

.user-actions button {
  background-color: #f44336;
  /* 红色背景 */
  color: white;
  /* 白色文字 */
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.user-actions button:hover {
  background-color: #d32f2f;
  /* 深红色背景 */
}
</style>