<template>
  <div class="documents-container">
    <header class="documents-header">
      <h1>文档列表</h1>
      <hr class="divider" />
      <div class="search-area">
        <input type="text" v-model="searchQuery" placeholder="搜索文档..." class="search-box" />
        <button @click="searchDocuments" class="search-btn">搜索</button>
        <button @click="openModal" class="add-document-btn">添加文档</button>
      </div>
    </header>
    <main>
      <table class="document-table">
        <thead>
          <tr>
            <th>文档名称</th>
            <th>权限</th>
            <th>创建人</th>
            <th>创建时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in filteredDocuments" :key="document.id">
            <td>{{ document.name }}</td>
            <td>{{ document.permission }}</td>
            <td>{{ document.creator }}</td>
            <td>{{ document.creationTime }}</td>
            <td>
              <button @click="viewDetails(document)">详情</button>
              <button @click="confirmDeletion(document)">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </main>

    <!-- 添加文档弹出窗口 -->
    <div v-if="isModalOpen" class="modal">
      <div class="modal-content">
        <span @click="closeModal" class="close-btn">&times;</span>
        <h2>添加文档</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="document-name">文档名称:</label>
            <input type="text" id="document-name" v-model="newDocument.name" required />
          </div>
          <div class="form-group">
            <label for="permission">权限:</label>
            <select id="permission" v-model="newDocument.permission">
              <option value="所有人">所有人</option>
              <option value="只有我">只有我</option>
            </select>
          </div>
          <div class="form-group">
            <label for="document-upload">上传文档:</label>
            <input type="file" id="document-upload" @change="handleFileUpload" />
          </div>
          <button type="submit" class="submit-btn">提交</button>
        </form>
      </div>
    </div>

    <!-- 文档详情弹出窗口 -->
    <div v-if="isDetailsModalOpen" class="modal">
      <div class="modal-content">
        <span @click="closeDetailsModal" class="close-btn">&times;</span>
        <h2>文档详情</h2>
        <div class="details-group">
          <p><strong>文档名称:</strong> {{ selectedDocument.name }}</p>
          <p><strong>权限:</strong> {{ selectedDocument.permission }}</p>
          <p><strong>创建人:</strong> {{ selectedDocument.creator }}</p>
          <p><strong>创建时间:</strong> {{ selectedDocument.creationTime }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false,
      isDetailsModalOpen: false,
      searchQuery: '',
      documents: JSON.parse(localStorage.getItem('documents')) || [],
      newDocument: {
        name: '',
        permission: '所有人',
        uploadFile: null,
        creator: '当前用户', // 这里可以替换为实际的当前用户
        creationTime: new Date().toLocaleString(),
      },
      selectedDocument: {},
    };
  },
  computed: {
    filteredDocuments() {
      return this.documents.filter((document) =>
        document.name.includes(this.searchQuery) ||
        document.permission.includes(this.searchQuery) ||
        document.creator.includes(this.searchQuery)
      );
    },
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.newDocument = { name: '', permission: '所有人', uploadFile: null, creator: '当前用户', creationTime: new Date().toLocaleString() }; // 重置表单
    },
    openDetailsModal() {
      this.isDetailsModalOpen = true;
    },
    closeDetailsModal() {
      this.isDetailsModalOpen = false;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.newDocument.uploadFile = file; // 保存上传文件
    },
    submitForm() {
      if (this.newDocument.uploadFile) {
        const newDocumentEntry = { ...this.newDocument, id: Date.now() };
        this.documents.push(newDocumentEntry);
        localStorage.setItem('documents', JSON.stringify(this.documents)); // 保存到本地存储
        this.closeModal();
      } else {
        alert("请上传一个文档！");
      }
    },
    searchDocuments() {
      console.log('搜索文档:', this.searchQuery);
    },
    viewDetails(document) {
      this.selectedDocument = document;
      this.openDetailsModal();
    },
    confirmDeletion(document) {
      if (confirm(`确定要删除文档 "${document.name}" 吗？`)) {
        this.documents = this.documents.filter((d) => d.id !== document.id);
        localStorage.setItem('documents', JSON.stringify(this.documents)); // 更新本地存储
      }
    },
  },
};
</script>

<style scoped>
.documents-container {
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.documents-header {
  position: relative;
  margin-bottom: 20px;
}

h1 {
  margin: 0;
  font-size: 24px;
}

.divider {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.search-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.search-box {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 0px;
}

.search-btn{padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap; /* 防止按钮文字换行 */
  margin-right: 10px; /* 增加按钮之间的间距 */
  display: flex;
  align-items: center; /* 垂直居中 */
  height: 40px; /* 设置与搜索框相同的高度 */
  margin-bottom: 10px;
}
.add-document-btn {padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap; /* 防止按钮文字换行 */
  display: flex;
  align-items: center; /* 垂直居中 */
  height: 40px; /* 设置与搜索框相同的高度 */
  margin-bottom: 10px;
}

.search-btn:hover,
.add-document-btn:hover {
  background-color: #0056b3;
}

.document-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.document-table th,
.document-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.document-table th {
  background-color: #f2f2f2;
}

.document-table tbody tr:hover {
  background-color: #f1f1f1;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
select,
input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
}
</style>
