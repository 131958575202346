<template>
  <div class="register-container">
    <div class="register-form">
      <h2>注册</h2>
      <form @submit.prevent="handleRegister">
        <div class="form-group">
          <label for="username">用户名:</label>
          <input type="text" v-model="username" required>
        </div>
        <div class="form-group">
          <label for="phone">手机号:</label>
          <input type="text" v-model="phone" required>
        </div>
        <div class="form-group">
          <label for="password">密码:</label>
          <input type="password" v-model="password" required>
        </div>
        <div class="form-group">
          <label for="confirmPassword">确认密码:</label>
          <input type="password" v-model="confirmPassword" required>
        </div>
        <button type="submit" class="btn">注册</button>
      </form>
      <router-link to="/login" class="login-link">已有账号？登录</router-link>
    </div>
  </div>
</template>

<script>
//import axios from 'axios';  // 确保 axios 被正确引入
import { registerUser } from '../api';  // 确认导入registerUser函数
export default {
  data() {
    return {
      username: '',
      phone: '',
      password: '',
      confirmPassword: ''
    };
  },
  methods: {
    async handleRegister() {
      if (this.password !== this.confirmPassword) {
        alert('两次输入的密码不一致');
        return;
      }

      try {
        // 发送注册请求
        //await axios.post('/api/register', {
          //username: this.username,
          //phone: this.phone,
          //password: this.password
        //});
        await registerUser({
                    username: this.username,
                    phone: this.phone,
                    password: this.password
                });
        alert('注册成功!');
        this.$router.push('/login');  // 注册成功后跳转到登录页
      } catch (error) {
        console.error('注册失败:', error);
        alert('注册失败，请重试');
      }
    }

  }
};
</script>

<style>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  width: 100%;
  /* 占满整个宽度 */
}

.register-form {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  /* 最大宽度 */
}

.register-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  /* 包含内边距和边框 */
}

.btn {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.btn:hover {
  background-color: #45a049;
}

.login-link {
  display: block;
  text-align: center;
  margin-top: 10px;
  color: #333;
  text-decoration: none;
}

.login-link:hover {
  text-decoration: underline;
}
</style>