<template>
    <div class="library-detail-container">
        <div class="library-detail-header">
            <h1>用例库详情</h1>
            <button class="add-case-btn" @click="openNewCaseModal">新建用例</button>
            <hr class="divider" />
        </div>

        <table class="case-table">
            <thead>
                <tr>
                    <th>用例编号</th>
                    <th>用例标题</th>
                    <th>设计方法</th>
                    <th>前置条件</th>
                    <th>测试步骤</th>
                    <th>预期结果</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="cases.length === 0">
                    <td colspan="7">没有找到用例，点击上面按钮添加新的用例。</td>
                </tr>
                <tr v-for="caseItem in cases" :key="caseItem.id">
                    <td>{{ caseItem.caseId }}</td>
                    <td>{{ caseItem.title }}</td>
                    <td>{{ caseItem.designMethod }}</td>
                    <td>{{ caseItem.precondition }}</td>
                    <td>{{ caseItem.testSteps }}</td>
                    <td>{{ caseItem.expectedResult }}</td>
                    <td>
                        <button @click="openEditModal(caseItem)">编辑</button>
                        <button @click="confirmDelete(caseItem.id)">删除</button>
                    </td>
                </tr>
            </tbody>
        </table>


        <div v-if="isNewCaseModalOpen" class="modal">
            <div class="modal-content">
                <span @click="closeNewCaseModal" class="close-btn">&times;</span>
                <h2>新增用例</h2>
                <form @submit.prevent="createCase">
                    <div class="form-group">
                        <label for="caseId">用例编号</label>
                        <input type="text" id="caseId" placeholder="用例编号" v-model="newCaseId" />
                    </div>
                    <div class="form-group">
                        <label for="caseTitle">用例标题</label>
                        <input type="text" id="caseTitle" placeholder="用例标题" v-model="newCaseTitle" />
                    </div>
                    <div class="form-group">
                        <label for="designMethod">设计方法</label>
                        <input type="text" id="designMethod" placeholder="设计方法" v-model="newCaseDesignMethod" />
                    </div>
                    <div class="form-group">
                        <label for="precondition">前置条件</label>
                        <input type="text" id="precondition" placeholder="前置条件" v-model="newCasePrecondition" />
                    </div>
                    <div class="form-group">
                        <label for="testSteps">测试步骤</label>
                        <textarea id="testSteps" placeholder="测试步骤" v-model="newCaseSteps"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="expectedResult">预期结果</label>
                        <textarea id="expectedResult" placeholder="预期结果" v-model="newCaseExpectedResult"></textarea>
                    </div>
                    <button type="submit" class="submit-btn">创建</button>
                </form>
            </div>
        </div>


        <div v-if="isEditModalOpen" class="modal">
            <div class="modal-content">
                <span @click="closeEditModal" class="close-btn">&times;</span>
                <h2>编辑用例</h2>
                <form @submit.prevent="updateCase">
                    <div class="form-group">
                        <label for="editCaseId">用例编号</label>
                        <input type="text" id="editCaseId" v-model="currentCase.caseId" disabled />
                    </div>
                    <div class="form-group">
                        <label for="editCaseTitle">用例标题</label>
                        <input type="text" id="editCaseTitle" v-model="currentCase.title" />
                    </div>
                    <div class="form-group">
                        <label for="editDesignMethod">设计方法</label>
                        <input type="text" id="editDesignMethod" v-model="currentCase.designMethod" />
                    </div>
                    <div class="form-group">
                        <label for="editPrecondition">前置条件</label>
                        <input type="text" id="editPrecondition" v-model="currentCase.precondition" />
                    </div>
                    <div class="form-group">
                        <label for="editTestSteps">测试步骤</label>
                        <textarea id="editTestSteps" v-model="currentCase.testSteps"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="editExpectedResult">预期结果</label>
                        <textarea id="editExpectedResult" v-model="currentCase.expectedResult"></textarea>
                    </div>
                    <button type="submit" class="submit-btn">更新</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['library'],
    data() {
        return {
            cases: [],
            isNewCaseModalOpen: false,
            isEditModalOpen: false,
            newCaseId: '',
            newCaseTitle: '',
            newCaseDesignMethod: '',
            newCasePrecondition: '',
            newCaseSteps: '',
            newCaseExpectedResult: '',
            currentCase: {},
            libId:'',
        };
    },
    created() {
        const libraryId = this.$route.params.id; // 获取库 ID
        this.fetchCasesByLibraryId(libraryId); // 根据 ID 获取用例信息
        this.libId = libraryId;

    },
    methods: {
        fetchCasesByLibraryId(id) {
            const libraries = JSON.parse(localStorage.getItem('libraries')) || [];
            const library = libraries.find(lib => lib.id === id);
            if (library) {
                this.cases = library.cases; // 将找到的用例存入 cases 数组
            }
        },
        openNewCaseModal() {
            this.isNewCaseModalOpen = true;
        },
        closeNewCaseModal() {
            this.isNewCaseModalOpen = false;
            this.newCaseId = '';
            this.newCaseTitle = '';
            this.newCaseDesignMethod = '';
            this.newCasePrecondition = '';
            this.newCaseSteps = '';
            this.newCaseExpectedResult = '';
        },
        createCase() {
            // 创建新用例对象
            const newCase = {
                id: String(Date.now()),
                caseId: this.newCaseId || `TC${String(this.cases.length + 1).padStart(3, '0')}`,
                title: this.newCaseTitle,
                designMethod: this.newCaseDesignMethod,
                precondition: this.newCasePrecondition,
                testSteps: this.newCaseSteps,
                expectedResult: this.newCaseExpectedResult,
            };

            // 从 localStorage 获取库数据
            const libraries = JSON.parse(localStorage.getItem('libraries')) || [];
            const libraryIndex = libraries.findIndex(lib => lib.id === this.libId);

            if (libraryIndex !== -1) {
                // 增加新用例
                libraries[libraryIndex].cases.push(newCase);
                libraries[libraryIndex].updatedAt = new Date().toISOString().split('T')[0]; // 更新用例库更新时间
                localStorage.setItem('libraries', JSON.stringify(libraries)); // 保存到 localStorage
                console.log("New case added and library updated:", libraries[libraryIndex]); // 调试信息
            } else {
                console.error("Library not found!"); // 如果未找到库，记录错误
            }
            
            // 本地更新表格
            this.cases.push(newCase);
            this.closeNewCaseModal(); // 关闭弹窗
            this.$emit('updateTimestamp', new Date().toISOString().split('T')[0]); // 触发父组件更新
        },
        openEditModal(caseItem) {
            this.currentCase = { ...caseItem }; // 复制以避免直接修改
            this.isEditModalOpen = true;
        },
        closeEditModal() {
            this.isEditModalOpen = false;
            this.currentCase = {};
        },
        updateCase() {
            const index = this.cases.findIndex(caseItem => caseItem.id === this.currentCase.id);
            if (index !== -1) {
                this.cases.splice(index, 1, this.currentCase);
                // 更新对应用例库数据
                const libraries = JSON.parse(localStorage.getItem('libraries')) || [];
                const libraryIndex = libraries.findIndex(lib => lib.id === this.libId);
                if (libraryIndex !== -1) {
                    libraries[libraryIndex].updatedAt = new Date().toISOString().split('T')[0]; // 更新时间
                    localStorage.setItem('libraries', JSON.stringify(libraries));
                }
            }
            this.closeEditModal();
            this.$emit('updateTimestamp', new Date().toISOString().split('T')[0]); // 触发父组件更新
        },
        confirmDelete(caseId) {
            const confirmed = confirm('确认是否删除此用例？');
            if (confirmed) {
                this.cases = this.cases.filter(caseItem => caseItem.id !== caseId);
                this.updateLibraryTimestamp(); // 更新用例库的时间戳
            }
        },
        updateLibraryTimestamp() {
            const currentDate = new Date().toISOString().split('T')[0];
            this.$emit('updateTimestamp', currentDate); // 触发父组件的事件
        },
    },
};
</script>

<style scoped>
:root {
    --dock-width: 220px;
    /* 定义dock栏的宽度 */
}

.library-detail-container {
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
}

/* 媒体查询：当屏幕宽度小于 768px 时，调整布局 */
@media (max-width: 768px) {
    :root {
        --dock-width: 180px;
        /* 调整dock栏的宽度 */
    }

    .library-detail-container {
        width: calc(100vw - var(--dock-width));
        /* 动态计算内容区域的宽度 */
        margin-left: var(--dock-width);
        /* 给内容留出dock的空间 */
    }

}

/* 媒体查询：当屏幕宽度小于 480px 时，进一步调整布局 */
@media (max-width: 480px) {
    :root {
        --dock-width: 150px;
        /* 进一步调整dock栏的宽度 */
    }

    .library-detail-container {
        width: calc(100vw - var(--dock-width));
        /* 动态计算内容区域的宽度 */
        margin-left: var(--dock-width);
        /* 给内容留出dock的空间 */
    }
}

.library-detail-header {
    position: relative;
}

h1 {
    margin: 0;
    font-size: 24px;
}

.add-case-btn {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
}

.add-case-btn:hover {
    background-color: #218838;
}

.divider {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 10px 0;
}

.case-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.case-table th,
.case-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.case-table th {
    background-color: #f2f2f2;
}

.case-table tbody tr:hover {
    background-color: #f9f9f9;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
textarea,
select,
input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #218838;
}
</style>