<template>
  <div class="knowledgebase-detail">
    <h1>知识库详情</h1>
    <div class="slices-container">
      <div class="slice" v-for="slice in knowledgeSlice" :key="slice.id">
        <h2>{{ slice.title }}</h2>
        <p>{{ slice.content }}</p>
        <div class="slice-controls">
          <button @click="openEditSlice(slice)">编辑</button>
          <button @click="confirmDelete(slice)">删除</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      knowledgeSlice: [], // 用于保存切片数据
    };
  },
  created() {
    this.fetchKnowledgeBaseDetails();
  },
  methods: {
    fetchKnowledgeBaseDetails() {
      // 从路径参数获取知识库ID
      const knowledgeId = this.$route.params.id;
      const knowledgeBase = JSON.parse(localStorage.getItem('knowledgeBase')) || [];
      const knowledgeItem = knowledgeBase.find(k => k.id === parseInt(knowledgeId));
      this.knowledgeSlice = knowledgeItem ? knowledgeItem.slices : []; // 加载切片数据
    },
    confirmDelete(slice) {
      if (confirm(`确定要删除切片 "${slice.title}" 吗？`)) {
        // 从当前切片中删除，并更新状态
        this.knowledgeSlice = this.knowledgeSlice.filter(s => s.id !== slice.id);
        // 在这里你可能需要保存到本地存储，依据具体需求而定
      }
    },
    openEditSlice(slice) {
      // 跳转到编辑切片页面，可以使用路由进行导航
      this.$router.push({ name: 'EditSlice', params: { id: slice.id } });
    },
  },
};
</script>

<style scoped>
.knowledgebase-detail {
  padding: 20px;
}

.slices-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.slice {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  width: calc(33% - 10px); /* 让切片在行内显示 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slice-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}
</style>
