<template>
  <div class="login-container">
    <div class="login-form">
      <h2>登录</h2>
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="identifier">用户名或手机号:</label>
          <input type="text" v-model="identifier" required>
        </div>
        <div class="form-group">
          <label for="password">密码:</label>
          <input type="password" v-model="password" required>
        </div>
        <button type="submit" class="btn">登录</button>
      </form>
      <router-link to="/register" class="register-link">没有账号？注册</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // 确保 axios 被正确引入
import { loginUser } from '../api';  // 确认导入registerUser函数

export default {
  data() {
    return {
      identifier: '',
      password: ''
    };
  },
  methods: {
    async handleLogin() {
      try {
        // 发送登录请求
        //const response = await axios.post('/api/login', {
        //identifier: this.identifier,
        //password: this.password
        //});
        const response = await loginUser({ identifier: this.identifier, password: this.password });

        if (response.data && response.data.token && response.data.user) {
          const { token, user } = response.data;
          // 将 JWT 令牌存储到 localStorage 中
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          alert('登录成功!');
          this.$router.push('/');
        } else {
          alert('登录失败，请重试');
        }
      } catch (error) {
        alert('用户名或密码错误');
        console.error('Login error:', error);
      }
    }
  }
};
</script>

<style>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  width: 100%;
  /* 占满整个宽度 */
}

.login-form {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  /* 最大宽度 */
}

.login-form h2 {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  /* 包含内边距和边框 */
}

.btn {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.btn:hover {
  background-color: #45a049;
}

.register-link {
  display: block;
  text-align: center;
  margin-top: 10px;
  color: #333;
  text-decoration: none;
}

.register-link:hover {
  text-decoration: underline;
}
</style>