<template>
  <div class="test-cases-container">
    <div class="test-cases-header">
      <h1>测试用例库列表</h1>
      <hr class="divider" />
    </div>
    
    <div class="search-area">
      <input class="search-box" type="text" v-model="searchQuery" placeholder="搜索用例库..." />
      <button class="search-btn" @click="search">搜索</button>
      <button class="add-library-btn" @click="openCreateModal">新建用例库</button>
    </div>

    <table class="test-cases-table">
      <thead>
        <tr>
          <th>库名称</th>
          <th>描述</th>
          <th>更新时间</th>
          <th>更新人</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="library in filteredLibraries" :key="library.id">
          <td>{{ library.name }}</td>
          <td>{{ library.description }}</td>
          <td>{{ library.updatedAt }}</td>
          <td>{{ library.updatedBy }}</td>
          <td>
            <button class="view-btn" @click="viewLibrary(library.id)">查看</button>
            <button class="delete-btn" @click="confirmDelete(library.id)">删除</button>
          </td>
        </tr>
        <tr v-if="filteredLibraries.length === 0">
          <td colspan="5">没有找到用例库</td>
        </tr>
      </tbody>
    </table>



    <div v-if="isCreateModalOpen" class="modal">
      <div class="modal-content">
        <span @click="closeCreateModal" class="close-btn">&times;</span>
        <h2>新增测试用例库</h2>
        <form @submit.prevent="createLibrary">
          <div class="form-group">
            <label for="library-name">名称:</label>
            <input type="text" id="knowledge-name" v-model="newLibraryName" required />
          </div>
          <div class="form-group">
            <label for="library-description">描述:</label>
            <textarea id="library-description" v-model="newLibraryDescription" required></textarea>
          </div>
          <div class="form-group">
            <label for="library-upload">上传文档:</label>
            <input type="file" id="library-upload" @change="handleFileUpload" />
          </div>

          <button type="submit" class="submit-btn">提交</button>
        </form>
      </div>
    </div>

    <LibraryDetail 
      v-if="currentLibrary" 
      :library="currentLibrary" 
      @close="currentLibrary = null" 
      @updateTimestamp="updateLibraryTimestamp"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '',
      libraries: JSON.parse(localStorage.getItem('libraries')) || [],
      isCreateModalOpen: false,
      newLibraryName: '',
      newLibraryDescription: '',
      currentLibrary: null,
    };
  },
  computed: {
    filteredLibraries() {
      return this.libraries.filter(library =>
        library.name.includes(this.searchQuery)
      );
    },
  },
  methods: {
    search() {
      // 在计算属性中已经实现搜索逻辑
    },
    openCreateModal() {
      this.isCreateModalOpen = true;
    },
    closeCreateModal() {
      this.isCreateModalOpen = false;
      this.newLibraryName = '';
      this.newLibraryDescription = ''; // 清空输入框
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      // 处理文件导入逻辑
    },
    createLibrary() {
      const newLibrary = {
        id: String(Date.now()),
        name: this.newLibraryName,
        description: this.newLibraryDescription,
        updatedAt: new Date().toISOString().split('T')[0],
        updatedBy: '当前用户',
        cases: [],
      };

      this.libraries.push(newLibrary);
      localStorage.setItem('libraries', JSON.stringify(this.libraries));

      this.closeCreateModal(); // 创建后关闭弹窗
    },
    viewLibrary(libraryId) {
      this.$router.push({ name: 'LibraryDetail', params: { id: libraryId } });
    },
    confirmDelete(id) {
      const confirmed = confirm('确认是否删除选中的用例库？');
      if (confirmed) {
        this.libraries = this.libraries.filter(library => library.id !== id);
        localStorage.setItem('libraries', JSON.stringify(this.libraries));
      }
    },
    // 省略其他方法...
    updateLibraryTimestamp(date) {
      // 更新对应库的时间戳
      const libraries = JSON.parse(localStorage.getItem('libraries')) || [];
      const libraryIndex = libraries.findIndex(lib => lib.id === this.currentLibrary.id);
      if (libraryIndex !== -1) {
        libraries[libraryIndex].updatedAt = date;
        localStorage.setItem('libraries', JSON.stringify(libraries)); // 保存更新的时间
      }
    },
  },
};
</script>

<style scoped>
/* CSS 样式根据前面调整，实现与 KnowledgeBase 一致 */
.test-cases-container {
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.test-cases-header {
  position: relative;
}

h1 {
  margin: 0;
  font-size: 24px;
}

.divider {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.search-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.search-box {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  width: 200px; /* 调整搜索框的宽度 */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 0px;
}

.search-btn{
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap; /* 防止按钮文字换行 */
  margin-right: 10px; /* 增加按钮之间的间距 */
  display: flex;
  align-items: center; /* 垂直居中 */
  height: 40px; /* 设置与搜索框相同的高度 */
  margin-bottom: 10px;
}

.add-library-btn{
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap; /* 防止按钮文字换行 */
  display: flex;
  align-items: center; /* 垂直居中 */
  height: 40px; /* 设置与搜索框相同的高度 */
  margin-bottom: 10px;
}


.search-btn:hover,
.add-library-btn:hover,
.view-btn:hover,
.delete-btn:hover {
  background-color: #0056b3;
}

.test-cases-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.test-cases-table th,
.test-cases-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.test-cases-table th {
  background-color: #f2f2f2;
}

.test-cases-table tbody tr:hover {
  background-color: #f1f1f1;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
select,
input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
}
</style>
