// src/api.js
import axios from 'axios';

const API_BASE_URL = '/api';

// 创建 axios 实例
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// 注册用户
export const registerUser = (userData) => {
  return apiClient.post('/auth/register', userData);
};

// 登录用户
export const loginUser = (credentials) => {
  return apiClient.post('/auth/login', credentials);
};

// 创建知识库条�?
export const createKnowledge = (knowledgeData, token) => {
  return apiClient.post('/knowledge', knowledgeData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

// 获取所有知识库条目
export const getAllKnowledge = (token) => {
  return apiClient.get('/knowledge', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
