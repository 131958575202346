<template>
  <div class="image-link">
    <!-- 使点击范围只覆盖实际内容部分 -->
    <component 
      :is="isExternalLink(link) ? 'a' : 'router-link'" 
      :href="link" 
      :to="link" 
      class="image-link-element"
    >
      <img :src="imageSrc" :alt="altText" class="image"/>
      <span class="link-text">{{ linkText }}</span>
    </component>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    altText: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    linkText: {
      type: String,
      required: true
    }
  },
  methods: {
    // 判断链接是外部URL还是内部路由
    isExternalLink(link) {
      return link.startsWith('http');
    }
  }
}
</script>

<style scoped>
.image-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0; /* 减少内边距，只保留顶部和底部 */
  transition: transform 0.2s;
  text-align: center;
}

.image-link:hover {
  transform: scale(1.05);
}

.image-link-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.image-link img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.image-link .link-text {
  margin-top: 10px;
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.image-link .link-text:hover {
  text-decoration: underline;
}
</style>
