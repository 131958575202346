<!-- <template>
  <div id="app">
    <Dock />
    <div class="content">
        <router-view />
    </div>
  </div>
</template> -->
<template>
    <Dock />
    <div class="content">
        <router-view />
    </div>
</template>

<script>
import Dock from './components/Dock.vue';

export default {
  name: 'App',
  components: {
    Dock
  },
  mounted() {
    console.log('App mounted:', this.$el); // 输出当前实例的根元素
  },
};
</script>

<style>
#app {
  display: flex;
  height: 100vh; /* 让整个页面占满高度 */
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}


.content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f9faf9;
  display: flex;
}

</style>
