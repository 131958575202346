<template>
    <div class="edit-slice">
      <h1>编辑切片</h1>
      <form @submit.prevent="submitEditForm">
        <div class="form-group">
          <label for="slice-title">标题:</label>
          <input type="text" id="slice-title" v-model="currentSlice.title" required />
        </div>
        <div class="form-group">
          <label for="slice-content">内容:</label>
          <textarea id="slice-content" v-model="currentSlice.content" required></textarea>
        </div>
        <div class="form-group">
          <label for="slice-upload">上传文档:</label>
          <input type="file" id="slice-upload" @change="handleFileUpload" />
        </div>
        <button type="submit" class="submit-btn">保存</button>
      </form>
      <button @click="deleteSlice" class="delete-btn">删除切片</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentSlice: {
          title: '',
          content: '',
          uploadFile: null,
        },
      };
    },
    created() {
      this.fetchSliceDetails();
    },
    methods: {
      fetchSliceDetails() {
        const sliceId = this.$route.params.id;
        const knowledgeBase = JSON.parse(localStorage.getItem('knowledgeBase')) || [];
        for (let knowledge of knowledgeBase) {
          const slice = knowledge.slices.find(s => s.id === parseInt(sliceId));
          if (slice) {
            this.currentSlice = { ...slice };
            break; // 找到切片后停止循环
          }
        }
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        this.currentSlice.uploadFile = file; // 保存上传的文件
      },
      submitEditForm() {
        const knowledgeBase = JSON.parse(localStorage.getItem('knowledgeBase')) || [];
        for (let knowledge of knowledgeBase) {
          const sliceIndex = knowledge.slices.findIndex(s => s.id === this.currentSlice.id);
          if (sliceIndex !== -1) {
            knowledge.slices[sliceIndex] = this.currentSlice; // 更新切片
            break; // 找到切片后停止循环
          }
        }
        localStorage.setItem('knowledgeBase', JSON.stringify(knowledgeBase)); // 保存更新到本地存储
        alert('切片已保存！');
        this.$router.push('/knowledge-base'); // 导航回知识库列表
      },
      deleteSlice() {
        if (confirm(`确定要删除切片 "${this.currentSlice.title}" 吗？`)) {
          const knowledgeBase = JSON.parse(localStorage.getItem('knowledgeBase')) || [];
          for (let knowledge of knowledgeBase) {
            const sliceIndex = knowledge.slices.findIndex(s => s.id === this.currentSlice.id);
            if (sliceIndex !== -1) {
              knowledge.slices.splice(sliceIndex, 1); // 删除切片
              break; // 找到切片后停止循环
            }
          }
          localStorage.setItem('knowledgeBase', JSON.stringify(knowledgeBase)); // 更新本地存储
          alert('切片已删除！');
          this.$router.push('/knowledge-base'); // 导航回知识库列表
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .edit-slice {
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  textarea,
  input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-btn,
  .delete-btn {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #218838;
  }
  
  .delete-btn {
    background-color: #dc3545;
    margin-top: 10px;
  }
  
  .delete-btn:hover {
    background-color: #c82333;
  }
  </style>
  