<template>
  <div class="knowledgebase-container">
    <header class="knowledgebase-header">
      <h1>业务知识库列表</h1>
      <hr class="divider" />
      <div class="search-area">
        <input type="text" v-model="searchQuery" placeholder="搜索知识库..." class="search-box" />
        <button @click="searchKnowledgeBase" class="search-btn">搜索</button>
        <button @click="openAddModal" class="add-knowledge-btn">新增知识库</button>
        <button @click="openEditModal" class="edit-knowledge-btn">修改知识库</button>
      </div>
    </header>
    <main>
      <table class="knowledgebase-table">
        <thead>
          <tr>
            <th>名称</th>
            <th>描述</th>
            <th>权限</th>
            <th>更新时间</th>
            <th>更新人</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="knowledge in filteredKnowledgeBase" :key="knowledge.id">
            <td>{{ knowledge.name }}</td>
            <td>{{ knowledge.description }}</td>
            <td>{{ knowledge.permission }}</td>
            <td>{{ knowledge.updateTime }}</td>
            <td>{{ knowledge.updater }}</td>
            <td>
              <button @click="viewDetails(knowledge)">查看</button>
              <button @click="confirmDeletion(knowledge)">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </main>

    <!-- 新增知识库弹出窗口 -->
    <div v-if="isAddModalOpen" class="modal">
      <div class="modal-content">
        <span @click="closeAddModal" class="close-btn">&times;</span>
        <h2>新增知识库</h2>
        <form @submit.prevent="submitAddForm">
          <div class="form-group">
            <label for="knowledge-name">名称:</label>
            <input type="text" id="knowledge-name" v-model="newKnowledge.name" required />
          </div>
          <div class="form-group">
            <label for="knowledge-description">描述:</label>
            <textarea id="knowledge-description" v-model="newKnowledge.description" required></textarea>
          </div>
          <div class="form-group">
            <label for="knowledge-upload">上传文档:</label>
            <input type="file" id="knowledge-upload" @change="handleFileUpload" />
          </div>
          <div class="form-group">
            <label for="permission">权限:</label>
            <select id="permission" v-model="newKnowledge.permission">
              <option value="只有我">只有我</option>
              <option value="所有人">所有人</option>
            </select>
          </div>
          <div class="form-group">
            <label for="model-select">嵌入模型:</label>
            <select id="model-select" v-model="newKnowledge.model">
              <option value="模型1">模型1</option>
              <option value="模型2">模型2</option>
              <option value="模型3">模型3</option>
            </select>
          </div>
          <div class="form-group">
            <label>分段设置:</label>
            <div>
              <label>
                <input type="radio" v-model="newKnowledge.segmentSetting" value="自动分段" />
                自动分段
              </label>
              <label>
                <input type="radio" v-model="newKnowledge.segmentSetting" value="自定义分段" />
                自定义分段
              </label>
            </div>
          </div>
          <button type="submit" class="submit-btn">提交</button>
        </form>
      </div>
    </div>
  </div>
    
</template>

<script>
export default {
  data() {
    return {
      isAddModalOpen: false,
      isDetailsModalOpen: false,
      searchQuery: '',
      knowledgeBase: JSON.parse(localStorage.getItem('knowledgeBase')) || [],
      newKnowledge: {
        name: '',
        description: '',
        permission: '只有我',
        uploadFile: null,
        model: '模型1',
        segmentSetting: '自动分段',
        updater: '当前用户', // 可替换为实际的当前用户
        updateTime: new Date().toLocaleString(),
      },
      selectedKnowledge: {},
    };
  },
  computed: {
    filteredKnowledgeBase() {
      return this.knowledgeBase.filter((knowledge) =>
        knowledge.name.includes(this.searchQuery) ||
        knowledge.description.includes(this.searchQuery) ||
        knowledge.permission.includes(this.searchQuery)
      );
    },
  },
  methods: {
    openAddModal() {
      this.isAddModalOpen = true;
    },
    closeAddModal() {
      this.isAddModalOpen = false;
      this.newKnowledge = { name: '', description: '', permission: '只有我', uploadFile: null, model: '模型1', segmentSetting: '自动分段', updater: '当前用户', updateTime: new Date().toLocaleString() }; // 重置表单
    },
    openEditModal() {
      // 可以添加修改知识库的逻辑
    },
    openDetailsModal() {
      this.isDetailsModalOpen = true;
    },
    closeDetailsModal() {
      this.isDetailsModalOpen = false;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.newKnowledge.uploadFile = file; // 保存上传的文件
    },
    submitAddForm() {
      const newKnowledgeEntry = { ...this.newKnowledge, id: Date.now(), updateTime: new Date().toLocaleString() };
      this.knowledgeBase.push(newKnowledgeEntry);
      localStorage.setItem('knowledgeBase', JSON.stringify(this.knowledgeBase)); // 保存到本地存储
      this.closeAddModal();
    },
    searchKnowledgeBase() {
      console.log('搜索知识库:', this.searchQuery);
    },
    viewDetails(knowledge) {
        this.$router.push({ name: 'KnowledgeBaseDetail', params: { id: knowledge.id } })
            .then(() => {
                
                // 添加手动刷新，以确保页面更新
                location.reload(); // 你可以选择刷新页面
            })
            .catch((err) => {
                alert('导航失败：' + err);
            });
    
  } ,
    confirmDeletion(knowledge) {
      if (confirm(`确定要删除知识库 "${knowledge.name}" 吗？`)) {
        this.knowledgeBase = this.knowledgeBase.filter((k) => k.id !== knowledge.id);
        localStorage.setItem('knowledgeBase', JSON.stringify(this.knowledgeBase)); // 更新本地存储
      }
    },
  },
};
</script>

<style scoped>
.knowledgebase-container {
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.knowledgebase-header {
  position: relative;
  margin-bottom: 20px;
}

h1 {
  margin: 0;
  font-size: 24px;
}

.divider {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.search-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.search-box {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  width: 200px; /* 调整搜索框的宽度 */
  box-sizing: border-box; /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 0px;
}

.search-btn,
.add-knowledge-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap; /* 防止按钮文字换行 */
  margin-right: 10px; /* 增加按钮之间的间距 */
  display: flex;
  align-items: center; /* 垂直居中 */
  height: 40px; /* 设置与搜索框相同的高度 */
  margin-bottom: 10px;
}
.edit-knowledge-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap; /* 防止按钮文字换行 */
  display: flex;
  align-items: center; /* 垂直居中 */
  height: 40px; /* 设置与搜索框相同的高度 */
  margin-bottom: 10px;
}

.search-btn:hover,
.add-knowledge-btn:hover,
.edit-knowledge-btn:hover {
  background-color: #0056b3;
}

.knowledgebase-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.knowledgebase-table th,
.knowledgebase-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.knowledgebase-table th {
  background-color: #f2f2f2;
}

.knowledgebase-table tbody tr:hover {
  background-color: #f1f1f1;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
textarea,
select,
input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
}
</style>
