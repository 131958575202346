<template>
  <div class="projects-container">
    <header class="projects-header">
      <h1>项目列表</h1>
      <hr class="divider" />
      <div class="search-area">
        <input type="text" v-model="searchQuery" placeholder="搜索项目..." class="search-box" />
        <button @click="searchProjects" class="search-btn">搜索</button>
        <button @click="openModal" class="new-project-btn">新建项目</button>
      </div>
    </header>
    <main>
      <table class="project-table">
        <thead>
          <tr>
            <th>项目名称</th>
            <th>所属行业</th>
            <th>产品类型</th>
            <th>业务类型</th>
            <th>文档</th>
            <th>知识库</th>
            <th>创建人</th>
            <th>创建时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in filteredProjects" :key="project.id">
            <td>{{ project.name }}</td>
            <td>{{ project.industry }}</td>
            <td>{{ project.productType }}</td>
            <td>{{ project.businessType }}</td>
            <td class="filename-column">
              <div v-if="project.documents.length">
                <a v-for="doc in project.documents" :key="doc._id" :href="getFileUrl(doc.filename)" target="_blank"
                  rel="noopener noreferrer">
                  {{ doc.originalname }}
                </a>
              </div>
              <span v-else>无</span>
            </td>
            <td class="filename-column">
              <div v-if="project.knowledge.length">
                <a v-for="know in project.knowledge" :key="know._id" :href="getFileUrl(know.filename)" target="_blank"
                  rel="noopener noreferrer">
                  {{ know.originalname }}
                </a>
              </div>
              <span v-else>无</span>
            </td>
            <td>{{ project.creator.username }}</td>
            <td>{{ project.creationTime }}</td>
            <td>
              <button @click="viewDetails(project)">详情</button>
              <button @click="openEditModal(project)">编辑</button>
              <button @click="confirmDeletion(project)">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </main>

    <!-- 新建项目弹出窗口 -->
    <div v-if="isModalOpen" class="modal">
      <div class="modal-content">
        <span @click="closeModal" class="close-btn">&times;</span>
        <h2>新建项目</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="project-name">项目名称:</label>
            <input type="text" id="project-name" v-model="newProject.name" required />
          </div>
          <div class="form-group">
            <label for="industry">所属行业:</label>
            <select id="industry" v-model="newProject.industry" class="wide-select">
              <option v-for="industry in industries" :key="industry" :value="industry">{{ industry }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="product-type">产品类型:</label>
            <select id="product-type" v-model="newProject.productType" class="wide-select">
              <option v-for="productType in productTypes" :key="productType" :value="productType">{{ productType }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="business-type">业务类型:</label>
            <select id="business-type" v-model="newProject.businessType" class="wide-select">
              <option v-for="businessType in businessTypes" :key="businessType" :value="businessType">{{ businessType }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="document-upload">文档上传:</label>
            <input type="file" id="document-upload" @change="handleFileUpload($event, 'document')" />
          </div>
          <div class="form-group">
            <label for="knowledge-upload">知识库上传:</label>
            <input type="file" id="knowledge-upload" @change="handleFileUpload($event, 'knowledge')" />
          </div>
          <button type="submit" class="submit-btn">提交</button>
        </form>
      </div>
    </div>

    <!-- 项目编辑弹出窗口 -->
    <div v-if="isEditModalOpen" class="modal">
      <div class="modal-content">
        <span @click="closeEditModal" class="close-btn">&times;</span>
        <h2>编辑项目</h2>
        <form @submit.prevent="submitEditForm">
          <div class="form-group">
            <label for="edit-project-name">项目名称:</label>
            <input type="text" id="edit-project-name" v-model="selectedProject.name" required />
          </div>
          <div class="form-group">
            <label for="edit-industry">所属行业:</label>
            <select id="edit-industry" v-model="selectedProject.industry" class="wide-select">
              <option v-for="industry in industries" :key="industry" :value="industry">{{ industry }}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="edit-product-type">产品类型:</label>
            <select id="edit-product-type" v-model="selectedProject.productType" class="wide-select">
              <option v-for="productType in productTypes" :key="productType" :value="productType">{{ productType }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="edit-business-type">业务类型:</label>
            <select id="edit-business-type" v-model="selectedProject.businessType" class="wide-select">
              <option v-for="businessType in businessTypes" :key="businessType" :value="businessType">{{ businessType }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="edit-document-upload">文档上传:</label>
            <input type="file" id="edit-document-upload" @change="handleEditFileUpload($event, 'document')" />
          </div>
          <div class="form-group">
            <label for="edit-knowledge-upload">知识库上传:</label>
            <input type="file" id="edit-knowledge-upload" @change="handleEditFileUpload($event, 'knowledge')" />
          </div>
          <button type="submit" class="submit-btn">提交</button>
        </form>
      </div>
    </div>

    <!-- 项目详情弹出窗口 -->
    <div v-if="isDetailsModalOpen" class="modal">
      <div class="modal-content">
        <span @click="closeDetailsModal" class="close-btn">&times;</span>
        <h2>项目详情</h2>
        <div class="details-group">
          <p><strong>项目名称:</strong> {{ selectedProject.name }}</p>
          <p><strong>所属行业:</strong> {{ selectedProject.industry }}</p>
          <p><strong>产品类型:</strong> {{ selectedProject.productType }}</p>
          <p><strong>业务类型:</strong> {{ selectedProject.businessType }}</p>
          <p><strong>文档:</strong>
            <a v-for="doc in selectedProject.documents" :key="doc._id" :href="getFileUrl(doc.filename)" target="_blank">
              {{ doc.originalname }}
            </a>
            <span v-if="!selectedProject.documents.length">无</span>
          </p>
          <p><strong>知识库:</strong>
            <a v-for="know in selectedProject.knowledge" :key="know._id" :href="getFileUrl(know.filename)"
              target="_blank">
              {{ know.originalname }}
            </a>
            <span v-if="!selectedProject.knowledge.length">无</span>
          </p>
          <p><strong>创建人:</strong> {{ selectedProject.creator.username }}</p>
          <p><strong>创建时间:</strong> {{ selectedProject.creationTime }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      isModalOpen: false,
      isEditModalOpen: false,
      isDetailsModalOpen: false,
      searchQuery: '',
      projects: JSON.parse(localStorage.getItem('projects')) || [],
      newProject: {
        name: '',
        industry: '',
        productType: '',
        businessType: '',
        document: null,
        knowledge: null,
        creator: '',
        creationTime: new Date().toLocaleString(),
      },
      selectedProject: {},
      industries: ['航空航天', '电子信息', '互联网服务', '武器装备', '核工业', '学术', '新能源', '自动驾驶', '机器人'],
      productTypes: ['嵌入式软件', '系统', '模块'],
      businessTypes: ['需求分析', '功能测试', '性能测试', '可靠性测试', '可靠性分析', '安全分析'],
    };
  },
  computed: {
    filteredProjects() {
      return this.projects.filter((project) =>
        project.name.includes(this.searchQuery) ||
        project.industry.includes(this.searchQuery) ||
        project.productType.includes(this.searchQuery) ||
        project.businessType.includes(this.searchQuery) ||
        project.creator.username.includes(this.searchQuery)  // 搜索时使用用户名
      );
    },
  },
  created() {
    this.fetchProjects();
  },
  methods: {
    getFileUrl(fileName) {
      if (!fileName) return '#';
      return `http://localhost:50011/uploads/${fileName}`;
    },

    async fetchProjects() {
      try {
        const token = localStorage.getItem('token');
        console.log('Stored token:', token);  // 添加调试信
        if (!token) {
          console.error('Token is missing in localStorage');
          return;
        }

        const response = await axios.get('/api/projects', {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token') // 如果需要认证
          }
        });
        this.projects = response.data;
      } catch (error) {
        console.error('获取项目列表失败:', error);
      }
    },
    openModal() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user._id) {
        this.newProject.creator = user._id;  // 获取当前用户的 ObjectId
      }
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openDetailsModal() {
      this.isDetailsModalOpen = true;
    },
    closeDetailsModal() {
      this.isDetailsModalOpen = false;
    },
    openEditModal(project) {
      this.selectedProject = { ...project };  // 复制选定项目
      this.isEditModalOpen = true;
    },
    closeEditModal() {
      this.isEditModalOpen = false;
    },
    handleFileUpload(event, type) {
      const file = event.target.files[0];
      if (type === 'document') {
        this.newProject.document = file;
      } else if (type === 'knowledge') {
        this.newProject.knowledge = file;
      }
      console.log(`Uploaded ${type} file:`, file);
    },
    handleEditFileUpload(event, type) {
      const file = event.target.files[0];
      if (type === 'document') {
        this.selectedProject.document = file;
      } else if (type === 'knowledge') {
        this.selectedProject.knowledge = file;
      }
      console.log(`Updated ${type} file:`, file);
    },
    async submitForm() {
      try {
        const formData = new FormData();
        formData.append('name', this.newProject.name);
        formData.append('industry', this.newProject.industry);
        formData.append('productType', this.newProject.productType);
        formData.append('businessType', this.newProject.businessType);
        formData.append('creator', this.newProject.creator);
        formData.append('creationTime', this.newProject.creationTime);

        if (this.newProject.document) {
          formData.append('document', this.newProject.document);
        }
        if (this.newProject.knowledge) {
          formData.append('knowledge', this.newProject.knowledge);
        }
        // 打印 FormData 内容
        for (let pair of formData.entries()) {
          console.log(pair[0] + ', ' + pair[1]);
        }

        const response = await axios.post('/api/projects', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('token') // 如果需要认证
          }
        });

        // 获取新创建项目信息
        const project = await axios.get(`/api/projects/${response.data._id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        });

        this.projects.push(project.data);
      } catch (error) {
        console.error('创建项目失败:', error);
      }
      this.closeModal();
    },
    async submitEditForm() {
      try {
        const formData = new FormData();
        formData.append('name', this.selectedProject.name);
        formData.append('industry', this.selectedProject.industry);
        formData.append('productType', this.selectedProject.productType);
        formData.append('businessType', this.selectedProject.businessType);
        formData.append('creator', this.selectedProject.creator._id);
        formData.append('creationTime', this.selectedProject.creationTime);
        // 确认文件被正确绑定
        if (this.selectedProject.document instanceof File) {
          formData.append('document', this.selectedProject.document);
        } else {
          console.log('Document is not a File');
        }
        if (this.selectedProject.knowledge instanceof File) {
          formData.append('knowledge', this.selectedProject.knowledge);
        } else {
          console.log('Knowledge is not a File');
        }

        console.log('Updating project with ID:', this.selectedProject._id);
        console.log('Document:', this.selectedProject.document);
        console.log('Knowledge:', this.selectedProject.knowledge);
        const response = await axios.put(
          `/api/projects/${this.selectedProject._id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('token')  // 如果需要认证
            }
          }
        );

        // 获取已更新项目信息
        const updatedProject = await axios.get(`/api/projects/${this.editingProject._id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        });


        const index = this.projects.findIndex((p) => p._id === this.editingProject._id);
        if (index !== -1) {
          // 直接更新数组中的元素
          //this.projects[index] = response.data;
          this.projects.splice(index, 1, updatedProject.data);
        }
      } catch (error) {
        console.error('更新项目失败:', error);
      }
      this.closeEditModal();
    },
    searchProjects() {
      console.log('搜索项目:', this.searchQuery);
    },
    viewDetails(project) {
      this.selectedProject = project;
      this.openDetailsModal();
    },
    async confirmDeletion(project) {
      console.log(`Deleting project with ID: ${project._id}`);
      if (confirm(`确定要删除项目 "${project.name}" 吗？`)) {
        try {
          const response = await axios.delete(`/api/projects/${project._id}`, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token') // 如果需要认证
            }
          });
          if (response.status === 200) {
            this.projects = this.projects.filter((p) => p._id !== project._id);
            console.error('删除项目成功');
          } else {
            console.error('服务器端删除项目失败:', response.data.message);
          }
        } catch (error) {
          console.error('删除项目失败:', error);
        }
      }
    },
  },
};
</script>

<style scoped>
.projects-container {
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.projects-header {
  position: relative;
}

h1 {
  margin: 0;
  font-size: 24px;
}

.divider {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.search-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.search-box {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
  width: 200px;
  /* 调整搜索框的宽度 */
  box-sizing: border-box;
  /* 确保内边距和边框包含在宽度内 */
  margin-bottom: 0px;
}

.search-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  /* 防止按钮文字换行 */
  margin-right: 10px;
  /* 增加按钮之间的间距 */
  display: flex;
  align-items: center;
  /* 垂直居中 */
  height: 40px;
  /* 设置与搜索框相同的高度 */
  margin-bottom: 10px;
}

.new-project-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  /* 防止按钮文字换行 */
  display: flex;
  align-items: center;
  /* 垂直居中 */
  height: 40px;
  /* 设置与搜索框相同的高度 */
  margin-bottom: 10px;
}

.search-btn:hover {
  background-color: #0056b3;
}

.new-project-btn:hover {
  background-color: #0056b3;
}

.project-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.project-table th,
.project-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.project-table th {
  background-color: #f2f2f2;
}

.project-table tbody tr:hover {
  background-color: #f1f1f1;
}

/* 新增样式 */
.project-table .filename-column {
  max-width: 200px;
  /* 设置最大宽度 */
  white-space: nowrap;
  /* 不换行 */
  overflow: hidden;
  /* 溢出隐藏 */
  text-overflow: ellipsis;
  /* 溢出用省略号表示 */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
select,
input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
}

.progress-bar {
  height: 20px;
  background: #3498db;
  width: 0;
  transition: width 0.3s;
}
</style>
