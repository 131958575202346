<template>
    <div class="functional-test">
        <h2>功能测试</h2>
        <div class="step-nav">
            <div v-for="(step, index) in steps" :key="index" :class="['step', { 'active': currentStep === index }]">
                <span>{{ step }}</span>
                <span v-if="index < steps.length - 1" class="arrow"></span>
            </div>
        </div>
        <hr class="divider" />

        <!-----------0.项目选择----------->
        <div v-if="currentStep === 0" class="upload-section">
            <div class="search-area">
                <input type="text" v-model="searchQuery" placeholder="搜索项目..." class="search-box" />
                <button @click="searchProjects" class="search-btn">搜索</button>
                <button @click="openModal" class="new-project-btn">新建功能测试项目</button>
            </div>
            <table class="project-table">
                <thead>
                    <tr>
                        <th>选择项目</th>
                        <th>项目名称</th>
                        <th>所属行业</th>
                        <th>产品类型</th>
                        <th>文档</th>
                        <th>知识库</th>
                        <th>创建人</th>
                        <th>创建时间</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(project, index) in filteredProjects" :key="project.id">
                        <td>
                            <input type="radio" name="selectedProject" :value="project" v-model="selectedProject" />
                        </td>
                        <td>{{ project.name }}</td>
                        <td>{{ project.industry }}</td>
                        <td>{{ project.productType }}</td>
                        <td class="filename-column">
                            <div v-if="project.documents.length">
                                <a v-for="doc in project.documents" :key="doc._id" :href="getFileUrl(doc.filename)"
                                    target="_blank" rel="noopener noreferrer">
                                    {{ doc.originalname }}
                                </a>
                            </div>
                            <span v-else>无</span>
                        </td>
                        <td class="filename-column">
                            <div v-if="project.knowledge.length">
                                <a v-for="know in project.knowledge" :key="know._id" :href="getFileUrl(know.filename)"
                                    target="_blank" rel="noopener noreferrer">
                                    {{ know.originalname }}
                                </a>
                            </div>
                            <span v-else>无</span>
                        </td>
                        <td>{{ project.creator.username }}</td>
                        <td>{{ project.creationTime }}</td>
                        <td>
                            <button @click="openEditModal(project)">修改需求文档</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="button-container">
                <button :disabled="!selectedProject" class="next-step-btn" @click="goToNextStep()">下一步</button>
            </div>
            <!-- 新建项目弹出窗口 -->
            <div v-if="isModalOpen" class="modal">
                <div class="modal-content">
                    <span @click="closeModal" class="close-btn">&times;</span>
                    <h2>新建项目</h2>
                    <form @submit.prevent="submitForm">
                        <div class="form-group">
                            <label for="project-name">项目名称:</label>
                            <input type="text" id="project-name" v-model="newProject.name" required />
                        </div>
                        <div class="form-group">
                            <label for="industry">所属行业:</label>
                            <select id="industry" v-model="newProject.industry" class="wide-select">
                                <option v-for="industry in industries" :key="industry" :value="industry">{{ industry }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="product-type">产品类型:</label>
                            <select id="product-type" v-model="newProject.productType" class="wide-select">
                                <option v-for="productType in productTypes" :key="productType" :value="productType">{{
                                    productType }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="business-type">业务类型:</label>
                            <select id="business-type" v-model="newProject.businessType" class="wide-select">
                                <option v-for="businessType in businessTypes" :key="businessType" :value="businessType">
                                    {{ businessType }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="document-upload">文档上传:</label>
                            <input type="file" id="document-upload" @change="handleFileUpload($event, 'document')" />
                        </div>
                        <div v-if="uploadProgress > 0" class="progress-container">
                            <div class="progress-bar" :style="{ width: uploadProgress + '%' }"></div>
                        </div>
                        <div class="form-group">
                            <label for="knowledge-upload">知识库上传:</label>
                            <input type="file" id="knowledge-upload" @change="handleFileUpload($event, 'knowledge')" />
                        </div>
                        <button type="submit" class="submit-btn">提交</button>
                    </form>
                </div>
            </div>

            <!-- 项目编辑弹出窗口 -->
            <div v-if="isEditModalOpen" class="modal">
                <div class="modal-content">
                    <span @click="closeEditModal" class="close-btn">&times;</span>
                    <h2>编辑需求文档</h2>
                    <form @submit.prevent="submitEditForm">
                        <div class="form-group">
                            <label for="edit-document-upload">文档上传:</label>
                            <input type="file" id="edit-document-upload"
                                @change="handleEditFileUpload($event, 'document')" />
                        </div>
                        <div v-if="uploadProgress > 0" class="progress-container">
                            <div class="progress-bar" :style="{ width: uploadProgress + '%' }"></div>
                        </div>
                        <button type="submit" class="submit-btn">提交</button>
                    </form>
                </div>
            </div>
        </div>

        <!-----------1.需求分析----------->
        <div v-if="currentStep === 1" class="analysis-section">
            <!--h3>需求分析结果与改进建议</h3-->
            <!-- 加载中状态 -->
            <div v-if="isLoading" class="loading-indicator">
                <div class="spinner"></div>
                正在加载需求分析结果...
            </div>
            <!-- 错误消息和重试按钮 -->
            <div v-if="!isLoading && errorMessage" class="error-message">
                <p>{{ errorMessage }}</p>
                <button v-if="errorMessage.trim()" @click="retryFetchAnalysis">重试</button>
            </div>

            <!-- 需求编辑框 -->
            <div class="editor-section">
                <h3>被测需求</h3>
                <textarea v-model="editedDocumentContent" class="editor" rows="10"></textarea>
                <input v-model="analysisCommand" type="text" class="command-input" placeholder="请输入您的分析指令" />
                <button class="analyze-button" @click="sendForAnalysis" :disabled="!analysisCommand.trim()"
                    :title="!analysisCommand.trim() ? '分析指令不能为空！' : ''">
                    继续需求分析
                </button>
                <!-- 总体分析结果和建议 -->
                <!-- <div v-if="!isLoading && overallAnalysis">
                    <p style='color: red;'>{{ overallAnalysis }}</p>
                </div> -->
            </div>

            <h3>分析结果及改进建议</h3>

            <div v-if="!isLoading && analysisResult.length > 0" class="req-result-section">
                <!-- 反馈的分析结果表格 -->
                <table class="fixed-width-table">
                    <thead>
                        <tr>
                            <th class="requirement-column">建议类型</th>
                            <th class="suggestion-column">建议</th>
                            <th class="action-column">操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(group, requirement) in groupedItems" :key="requirement">
                            <tr v-for="(item, index) in group" :key="index" :class="{ rejected: item.rejected }">
                                <td class="requirement-column" v-if="index === 0" :rowspan="group.length">{{ requirement
                                    }}</td>
                                <td class="suggestion-column">{{ item.suggestion }}</td>
                                <td class="action-column">
                                    <button @click="acceptReqItem(item)">接受</button>
                                    <button @click="rejectReqItem(item)" v-if="!item.rejected">拒绝</button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

                <!-- 没有数据的情况 -->
                <div v-if="!isLoading && analysisResult.length === 0 && analysisResult.length === 0">
                    没有需求分析结果。
                </div>
            </div>
            <!-- 已接受条目表格 -->
            <div v-if="acceptedReqItems.length > 0" class="accepted-items-section">
                <h4>已接受的条目</h4>
                <table class="fixed-width-table">
                    <thead>
                        <tr>
                            <th class="requirement-column">建议类型</th>
                            <th class="suggestion-column">建议</th>
                            <th class="action-column">操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(group, requirement) in groupedAcceptedItems" :key="requirement">
                            <tr v-for="(item, index) in group" :key="item.id">
                                <td class="requirement-column" v-if="index === 0" :rowspan="group.length">{{ requirement
                                    }}</td>
                                <td class="suggestion-column">
                                    <div v-if="editIndex === item.id">
                                        <input v-model="item.suggestion" @blur="editIndex = -1"
                                            @keyup.enter="editIndex = -1" class="editor-input" />
                                    </div>
                                    <div v-else @click="editIndex = item.id">
                                        {{ item.suggestion }}
                                    </div>
                                </td>
                                <td class="action-column">
                                    <button @click="removeReqItem(item.id)">删除</button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <!-- 下一个步骤 -->
            <div class="button-container">
                <!--<div class="previous-step-btn-container">-->
                <button class="previous-step-btn" @click="goToPreviousStep()">上一步</button>
                <!--</div>-->
                <!--<div class="next-step-btn-container">-->
                <button :disabled="isLoading || !selectedProject" class="next-step-btn" @click="goToNextStep()"
                    :title="(isLoading || !selectedProject) ? '分析中，无法操作！' : ''">
                    下一步
                </button>
                <!--</div>-->
            </div>
        </div>


        <!-----------2.测试分析----------->
        <div v-if="currentStep === 2" class="test-analysis-section">
            <h3>测试点列表</h3>

            <!-- 加载中状态 -->
            <div v-if="isLoading" class="loading-indicator">
                <div class="spinner"></div>
                正在加载测试分析结果...
            </div>

            <!-- 错误消息和重试按钮 -->
            <div v-if="!isLoading && errorMessage" class="error-message">
                <p>{{ errorMessage }}</p>
                <button v-if="errorMessage.trim()" @click="retryFetchTestAnalysis">重试</button>
            </div>

            <!-- 总体分析结果和建议
            <div v-if="!isLoading && overallTestAnalysis">
                <p style='color:red;'>{{ overallTestAnalysis }}</p>
            </div> -->

            <!-- 分析结果表格 -->
            <div v-if="!isLoading && testAnalysisResult.length > 0" class="test_ana-result-section">
                <!-- 没有数据的情况 -->
                <div v-if="!isLoading && testAnalysisResult.length === 0">
                    没有分析结果。
                </div>

                <!-- 测试点编辑框 -->
                <h4>测试点列表</h4>
                <div class="editor-section">
                    <!--h4>测试分析编辑</h4-->
                    <!--<textarea v-model="editedTestAnalysisContent" class="editor" rows="20"></textarea>-->
                    <div class="editor-section">
                        <table class="test-points-table">
                            <thead>
                                <tr>
                                    <th class="test-point-column">测试点</th>
                                    <th class="test-point-content-column">内容</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(testPoint, index) in testPoints" :key="index">
                                    <td class="test-point-column">测试点{{ index + 1 }}</td>
                                    <td class="test-point-content-column">
                                        <div v-if="editIndex === index">
                                            <input v-model="testPoints[index]" @blur="editIndex = -1"
                                                @keyup.enter="editIndex = -1" class="editor-input" />
                                        </div>
                                        <div v-else @click="editIndex = index">
                                            {{ testPoint }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <h4>测试分析指令</h4>
                        <input v-model="testAnalysisCommand" type="text" class="command-input"
                            placeholder="请输入您的分析指令" />

                        <button class="test-analyze-button" @click="sendForTestAnalysis"
                            :disabled="!testAnalysisCommand.trim()"
                            :title="!testAnalysisCommand.trim() ? '分析指令不能为空！' : ''">
                            继续测试分析
                        </button>
                    </div>

                    <!-- 下一个步骤 -->
                    <div class="button-container">
                        <!--<div class="previous-step-btn-container">-->
                        <button class="previous-step-btn" @click="goToPreviousStep()">上一步</button>
                        <!--</div>-->
                        <!--<div class="next-step-btn-container">-->
                        <button :disabled="!selectedProject" class="next-step-btn" @click="goToNextStep()">下一步</button>
                        <!--</div>-->
                    </div>
                </div>
            </div>
        </div>

        <!-----------3.用例生成----------->
        <div v-if="currentStep === 3" class="test-case-section">
            <h3>生成的测试用例</h3>

            <!-- 加载中状态 -->
            <div v-if="isLoading" class="loading-indicator">
                <div class="spinner"></div>
                正在加载测试用例生成结果...
            </div>

            <!-- 错误消息和重试按钮 -->
            <div v-if="!isLoading && errorMessage" class="error-message">
                <p>{{ errorMessage }}</p>
                <button v-if="errorMessage.trim()" @click="retryFetchTestcaseAnalysis">重试</button>
            </div>

            <!-- 总体分析结果和建议 -->
            <div v-if="!isLoading">
                <h4>用例生成指令</h4>
                <input v-model="testcaseAnalysisCommand" type="text" class="command-input" placeholder="请输入您的设计指令" />

                <button class="test-analyze-button" @click="sendForTestcaseAnalysis"
                    :disabled="!testcaseAnalysisCommand.trim()"
                    :title="!testcaseAnalysisCommand.trim() ? '设计指令不能为空！' : ''">
                    继续测试设计
                </button>
            </div>



            <!-- 分析结果表格 -->
            <div v-if="!isLoading && testcaseAnalysisResult.length > 0" class="req-result-section">
                <div class="accrej-all-container">
                    <button @click="acceptAllTestcases" class="accept-all-btn">全部接受</button>
                    <button @click="rejectAllTestcases" class="reject-all-btn">全部拒绝</button>
                </div>
                <table class="TestcaseAnaRes  resizable">
                    <thead>
                        <tr>
                            <th>用例标题</th>
                            <th>设计方法</th>
                            <th>前置条件</th>
                            <th>测试步骤</th>
                            <th>预期结果</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!--<tr v-for="(item, index) in testcaseAnalysisResult" :key="index">
                            <td>{{ item.title }}</td>
                            <td>{{ item.designMethod }}</td>
                            <td>{{ item.preCondition }}</td>
                            <td>{{ item.steps }}</td>
                            <td>{{ item.expectedResult }}</td>
                            <td>
                                <button @click="confirmTestcaseItem(index)"
                                    :disabled="item.accepted === true">保存</button>
                                <button @click="rejectTestcaseItem(index)"
                                    :disabled="item.accepted === false">丢弃</button>
                            </td>
                        </tr>-->
                        <tr v-for="(item, index) in testcaseAnalysisResult" :key="index">
                            <td @click="editField(index, 'title')">
                                <template v-if="editIndex === index && editFieldKey === 'title'">
                                    <textarea v-model="item.title" @blur="saveField" class="edit-textarea"></textarea>
                                </template>
                                <template v-else>
                                    {{ item.title }}
                                </template>
                            </td>
                            <td @click="editField(index, 'designMethod')">
                                <template v-if="editIndex === index && editFieldKey === 'designMethod'">
                                    <textarea v-model="item.designMethod" @blur="saveField"
                                        class="edit-textarea"></textarea>
                                </template>
                                <template v-else>
                                    {{ item.designMethod }}
                                </template>
                            </td>
                            <td @click="editField(index, 'preCondition')">
                                <template v-if="editIndex === index && editFieldKey === 'preCondition'">
                                    <textarea v-model="item.preCondition" @blur="saveField"
                                        class="edit-textarea"></textarea>
                                </template>
                                <template v-else>
                                    {{ item.preCondition }}
                                </template>
                            </td>
                            <td @click="editField(index, 'steps')">
                                <template v-if="editIndex === index && editFieldKey === 'steps'">
                                    <textarea v-model="item.steps" @blur="saveField" class="edit-textarea"></textarea>
                                </template>
                                <template v-else>
                                    {{ item.steps }}
                                </template>
                            </td>
                            <td @click="editField(index, 'expectedResult')">
                                <template v-if="editIndex === index && editFieldKey === 'expectedResult'">
                                    <textarea v-model="item.expectedResult"
                                        @blur="saveField"> class="edit-textarea"</textarea>
                                </template>
                                <template v-else>
                                    {{ item.expectedResult }}
                                </template>
                            </td>
                            <td>
                                <button @click="confirmTestcaseItem(index)"
                                    :disabled="item.accepted === true">保存</button>
                                <button @click="rejectTestcaseItem(index)"
                                    :disabled="item.accepted === false">丢弃</button>
                            </td>
                        </tr>
                    </tbody>
                </table>


                <!-- 没有数据的情况 -->
                <div v-if="!isLoading && testcaseAnalysisResult.length === 0">
                    没有测试用例生成结果。
                </div>
            </div>
            <!-- 已接受的用例 -->
            <h4>已接受的测试用例</h4>
            <div v-if="acceptedTestcases.length === 0">
                暂无。
            </div>
            <div v-if="acceptedTestcases.length > 0">
                <table class="TestcaseAnaRes resizable">
                    <thead>
                        <tr>
                            <th>用例标题</th>
                            <th>设计方法</th>
                            <th>前置条件</th>
                            <th>测试步骤</th>
                            <th>预期结果</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in acceptedTestcases" :key="index">
                            <td @click="editAcceptedField(index, 'title')">
                                <template v-if="acceptedEditIndex === index && acceptedEditFieldKey === 'title'">
                                    <textarea v-model="item.title" @blur="saveAcceptedField"
                                        class="edit-textarea"></textarea>
                                </template>
                                <template v-else>
                                    {{ item.title }}
                                </template>
                            </td>
                            <td @click="editAcceptedField(index, 'designMethod')">
                                <template v-if="acceptedEditIndex === index && acceptedEditFieldKey === 'designMethod'">
                                    <textarea v-model="item.designMethod" @blur="saveAcceptedField"
                                        class="edit-textarea"></textarea>
                                </template>
                                <template v-else>
                                    {{ item.designMethod }}
                                </template>
                            </td>
                            <td @click="editAcceptedField(index, 'preCondition')">
                                <template v-if="acceptedEditIndex === index && acceptedEditFieldKey === 'preCondition'">
                                    <textarea v-model="item.preCondition" @blur="saveAcceptedField"
                                        class="edit-textarea"></textarea>
                                </template>
                                <template v-else>
                                    {{ item.preCondition }}
                                </template>
                            </td>
                            <td @click="editAcceptedField(index, 'steps')">
                                <template v-if="acceptedEditIndex === index && acceptedEditFieldKey === 'steps'">
                                    <textarea v-model="item.steps" @blur="saveAcceptedField"
                                        class="edit-textarea"></textarea>
                                </template>
                                <template v-else>
                                    {{ item.steps }}
                                </template>
                            </td>
                            <td @click="editAcceptedField(index, 'expectedResult')">
                                <template
                                    v-if="acceptedEditIndex === index && acceptedEditFieldKey === 'expectedResult'">
                                    <textarea v-model="item.expectedResult" @blur="saveAcceptedField"
                                        class="edit-textarea"></textarea>
                                </template>
                                <template v-else>
                                    {{ item.expectedResult }}
                                </template>
                            </td>
                            <td>
                                <button @click="removeAcceptedTestcase(index)">删除</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <!-- 下一个步骤 -->
            <div class="button-container">
                <!--<div class="previous-step-btn-container">-->
                <button class="previous-step-btn" @click="goToPreviousStep()">上一步</button>
                <!--</div>-->
                <!--<div class="next-step-btn-container">-->
                <button :disabled="!selectedProject" class="next-step-btn" @click="goToNextStep()">下一步</button>
                <!--</div>-->
            </div>
        </div>
        <!-----------4.结果分析----------->
        <div v-if="currentStep === 4" class="functional-test-result-section">
            <h3>项目分析结果</h3>
            <h4>修改的需求文本</h4>
            <div class="editor-readonly" v-html="editedDocumentContent"></div>
            <div v-if="acceptedReqItems.length > 0" class="accepted-items-section">
                <h4>需求修改</h4>
                <table class="fixed-width-table">
                    <thead>
                        <tr>
                            <th class="requirement-column">建议类型</th>
                            <th class="suggestion-column">建议</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(group, requirement) in groupedAcceptedItems" :key="requirement">
                            <tr v-for="(item, index) in group" :key="item.id">
                                <td class="requirement-column" v-if="index === 0" :rowspan="group.length">{{
                                    requirement
                                }}</td>
                                <td class="suggestion-column">{{ item.suggestion }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>

            <h4>测试点</h4>
            <div class="editor-section">
                <div class="editor-readonly" v-html="editedTestAnalysisContent"></div>
                <table class="test-points-table">
                    <thead>
                        <tr>
                            <th class="test-point-column">测试点</th>
                            <th class="test-point-content-column">内容</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(testPoint, index) in testPoints" :key="index">
                            <td class="test-point-column">测试点{{ index + 1 }}</td>
                            <td class="test-point-content-column">
                                <div>{{ testPoint }}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 已接受的用例 -->
            <h4>测试用例</h4>
            <div v-if="acceptedTestcases.length > 0">
                <table class="TestcaseAnaRes resizable">
                    <thead>
                        <tr>
                            <th>用例标题</th>
                            <th>设计方法</th>
                            <th>前置条件</th>
                            <th>测试步骤</th>
                            <th>预期结果</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in acceptedTestcases" :key="index">
                            <td>{{ item.title }}</td>
                            <td>{{ item.designMethod }}</td>
                            <td>{{ item.preCondition }}</td>
                            <td>{{ item.steps }}</td>
                            <td>{{ item.expectedResult }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 下一个步骤 -->
            <div class="button-container">
                <!--<div class="previous-step-btn-container">-->
                <button class="previous-step-btn" @click="goToPreviousStep()">上一步</button>
                <!--</div>-->
                <button class="next-step-btn" @click="exportToWord">导出</button>
            </div>
        </div>
        <!-- 其他步骤同理，可以后续补充 -->

    </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import 'jquery-resizable-columns';
import { v4 as uuidv4 } from 'uuid';
import { Document, Packer, Paragraph, Table, TableCell, TableRow, TextRun, HeadingLevel, AlignmentType } from "docx";
import { saveAs } from "file-saver";

export default {
    name: "FunctionalTest",
    
    data() {
        return {
            currentStep: 0,
            isReChanged: false,
            steps: ["需求描述", "需求分析", "测试分析", "测试设计", "完成"],
            isModalOpen: false,
            isEditModalOpen: false,
            isDetailsModalOpen: false,
            searchQuery: '',
            projects: JSON.parse(localStorage.getItem('projects')) || [],
            newProject: {
                name: '',
                industry: '',
                productType: '',
                businessType: '',
                document: null,
                knowledge: null,
                creator: '',
                creationTime: new Date().toLocaleString(),
            },
            selectedProject: null,
            editingProject: null,
            industries: ['航空航天', '电子信息', '互联网服务', '武器装备', '核工业', '学术', '新能源', '自动驾驶', '机器人'],
            productTypes: ['嵌入式软件', '系统', '模块'],
            businessTypes: ['功能测试'],

            acceptedReqItems: [],  // 接受的需求修改建议
            editReqIndex: -1,//需求编辑索引
            confirmedItems: [],
            testCases: [],
            uploadProgress: 0,  // 新增的状态变量
            formattedItems: [], //格式化后的需求建议接受条目

            analysisResult: [],  // 需求分析结果
            isLoading: false,    // 是否在加载中
            overallAnalysis: '',  // 总体分析结果和建议
            overallTestAnalysis: '',
            overallTestcaseAnalysis: '',
            errorMessage: '',
            editedDocumentContent: '',  // 编辑后的文档内容
            editedTestAnalysisContent: '',
            editedTestcaseAnalysisContent: '',
            analysisCommand: '',        // 分析指令
            testAnalysisCommand: '',
            testAnalysisResult: [],
            formattedTestAnalysisContent: '',

            testcaseAnalysisResult: [],
            acceptedEditIndex: null, // 新增：用于已接受用例表格的编辑索引
            acceptedEditFieldKey: null, // 新增：用于已接受用例表格的编辑字段键
            acceptedTestcases: [],
            testcaseAnalysisCommand: '',
            isGenerating: false,

            editIndex: null,
            editFieldKey: null,
            lastAnalysisFunction: '',
            lastTestAnalysisFunction: '',
            lastTestcaseAnalysisFunction: '',

            isProjectChanged: true,//项目变更
            isDocumentChanged: true,//需求文档变更
            isReqTextChanged: true,//需求文本变更
            isReqSuggestionChanged: true,//需求修改建议变更
            isTestPointsChanged: true,//测试点变更
            isTestCasesChanged: true,//测试用例变更
        };
    },
    computed: {
        filteredProjects() {
            return this.projects.filter((project) =>
                (project.name.includes(this.searchQuery) ||
                    project.industry.includes(this.searchQuery) ||
                    project.productType.includes(this.searchQuery) ||
                    project.businessType.includes(this.searchQuery) ||
                    project.creator.username.includes(this.searchQuery)) &&
                project.businessType === '功能测试' // 只显示业务类型为“功能测试”的项目
            );
        },
        allAnalysisItems() {
            return this.analysisResult.reduce((acc, category) => {
                category.suggestions.forEach(suggestion => {
                    acc.push({
                        id: uuidv4(), // 生成唯一ID
                        requirement: category.requirement,
                        suggestion: suggestion,
                        rejected: category.rejectedMap && category.rejectedMap[suggestion] || false
                    });
                });
                return acc;
            }, []);
        },
        groupedItems() {
            return this.groupByRequirement(this.allAnalysisItems);
        },
        groupedAcceptedItems() {
            return this.groupByRequirement(this.acceptedReqItems);
        }
    },
    created() {
        this.fetchProjects();
    },
    watch: {
        selectedProject: 'handleSelectedProjectChange',
        editedDocumentContent: 'handleDocumentContentChange',
        acceptedReqItems: { handler: 'handleReqSuggestionChange', deep: true, },
        testPoints: { handler: 'handleTestPointsChange', deep: true, },
        acceptedTestcases: { handler: 'handleTestcasesChange', deep: true, },
    },
    methods: {
        getFileUrl(fileName) {
            if (!fileName) return '#';
            return `http://localhost:50011/uploads/${fileName}`;
        },
        handleSelectedProjectChange(newValue, oldValue) {//选中的项目发生变更
            console.log("项目切换，应触发分析：", oldValue, newValue);
            this.isProjectChanged = true;
            this.isReqTextChanged = true;
            this.isReqSuggestionChanged = true;
            this.isTestPointsChanged = true;
            this.isTestCasesChanged = true;
            console.log(this.isProjectChanged);
        },
        handleDocumentContentChange(newValue, oldValue) {//需求文本发生变更
            console.log('需求文本变更:', oldValue, newValue);
            this.isReqTextChanged = true;
            this.isTestPointsChanged = true;
            this.isTestCasesChanged = true;
            console.log(this.isReqTextChanged);
        },
        handleReqSuggestionChange(newValue, oldValue) {//接受的需求修改建议发生变更
            console.log('需求建议变更:', oldValue, newValue);
            this.isReqSuggestionChanged = true;
            this.isTestPointsChanged = true;
            this.isTestCasesChanged = true;
            console.log(this.isReqSuggestionChanged);
        },
        handleTestPointsChange(newValue, oldValue) {//测试点发生变更
            console.log("测试点变更，应触发分析：", oldValue, newValue);
            this.isTestPointsChanged = true;
            this.isTestCasesChanged = true;
            console.log(this.isTestPointsChanged);
        },
        handleTestCasesChange(newValue, oldValue) {//测试用例发生变更
            console.log("测试用例，应触发分析：", oldValue, newValue);
            this.isTestCasesChanged = true;
            console.log(this.isTestCasesChanged);
        },

        // 监听测试分析内容的变化
        handTestAnalysisContentChange(newValue, oldValue) {
            this.isTestAnalysisChanged = true;
        },



        async fetchProjects() {
            this.isReChanged = 0;
            try {
                const token = localStorage.getItem('token');
                console.log('Stored token:', token);  // 添加调试信
                if (!token) {
                    console.error('Token is missing in localStorage');
                    return;
                }

                const response = await axios.get('/api/projects', {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token') // 如果需要认证
                    }
                });
                this.projects = response.data;
            } catch (error) {
                console.error('获取项目列表失败:', error);
            }
        },
        openModal() {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user._id) {
                this.newProject.creator = user._id;  // 获取当前用户的 ObjectId
            }
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        openDetailsModal() {
            this.isDetailsModalOpen = true;
        },
        closeDetailsModal() {
            this.isDetailsModalOpen = false;
        },
        openEditModal(project) {
            this.editingProject = { ...project };  // 复制选定项目
            this.isEditModalOpen = true;
        },
        closeEditModal() {
            this.isEditModalOpen = false;
        },
        handleFileUpload(event, type) {
            this.isReChanged = 1;
            const file = event.target.files[0];
            if (type === 'document') {
                this.newProject.document = file;
            } else if (type === 'knowledge') {
                this.newProject.knowledge = file;
            }
            console.log(`Uploaded ${type} file:`, file);
        },
        handleEditFileUpload(event, type) {
            this.isReChanged = 1;
            const file = event.target.files[0];
            if (type === 'document') {
                this.editingProject.document = file;
            } else if (type === 'knowledge') {
                this.editingProject.knowledge = file;
            }
            console.log(`Updated ${type} file:`, file);
        },
        async submitForm() {
            try {
                this.uploadProgress = 0;  // 重置进度条
                const formData = new FormData();
                formData.append('name', this.newProject.name);
                formData.append('industry', this.newProject.industry);
                formData.append('productType', this.newProject.productType);
                formData.append('businessType', this.newProject.businessType);
                formData.append('creator', this.newProject.creator);
                formData.append('creationTime', this.newProject.creationTime);

                if (this.newProject.document) {
                    formData.append('document', this.newProject.document);
                }
                if (this.newProject.knowledge) {
                    formData.append('knowledge', this.newProject.knowledge);
                }
                // 打印 FormData 内容
                for (let pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }

                const response = await axios.post('/api/projects', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + localStorage.getItem('token') // 如果需要认证
                    },
                    onUploadProgress: progressEvent => {
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    }
                });

                // 获取新创建项目信息
                const project = await axios.get(`/api/projects/${response.data._id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });

                this.projects.push(project.data);
                this.isReChanged = 1;
            } catch (error) {
                console.error('创建项目失败:', error);
            }
            this.closeModal();
        },
        async submitEditForm() {
            try {
                this.uploadProgress = 0;  // 重置进度条
                const formData = new FormData();
                formData.append('name', this.editingProject.name);
                formData.append('industry', this.editingProject.industry);
                formData.append('productType', this.editingProject.productType);
                formData.append('businessType', this.editingProject.businessType);
                formData.append('creator', this.editingProject.creator._id);
                formData.append('creationTime', this.editingProject.creationTime);
                // 确认文件被正确绑定
                if (this.editingProject.document instanceof File) {
                    formData.append('document', this.editingProject.document);
                } else {
                    console.log('Document is not a File');
                }
                if (this.editingProject.knowledge instanceof File) {
                    formData.append('knowledge', this.editingProject.knowledge);
                } else {
                    console.log('Knowledge is not a File');
                }

                console.log('Updating project with ID:', this.editingProject._id);
                console.log('Document:', this.editingProject.document);
                console.log('Knowledge:', this.editingProject.knowledge);
                const response = await axios.put(
                    `/api/projects/${this.editingProject._id}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')  // 如果需要认证
                        },
                        onUploadProgress: progressEvent => {
                            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        }

                    }
                );

                // 获取已更新项目信息
                const updatedProject = await axios.get(`/api/projects/${this.editingProject._id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });


                const index = this.projects.findIndex((p) => p._id === this.editingProject._id);
                if (index !== -1) {
                    // 直接更新数组中的元素
                    //this.projects[index] = response.data;
                    this.projects.splice(index, 1, updatedProject.data);
                }
                this.isReChanged = 1;
            } catch (error) {
                console.error('更新项目失败:', error);
            }
            this.closeEditModal();
        },
        //handleFileUpload(event) {
        //const file = event.target.files[0];
        //this.uploadDocument(file);
        //},
        //async uploadDocument(file) {
        //const analysisResponse = await this.mockFetchAnalysis(file);
        //this.analysisResult = analysisResponse;
        //this.goToNextStep();
        //},
        submitDocument() {
            this.$refs.fileInput.click();
        },
        confirmItem(index) {
            const item = { ...this.analysisResult[index] };
            if (item.modifiedRequirement) {
                item.requirement = item.modifiedRequirement;
            }
            this.confirmedItems.push(item);
            this.analysisResult.splice(index, 1);
        },
        rejectItem(index) {
            this.analysisResult.splice(index, 1);
        },
        goToNextStep() {
            if (this.currentStep === 0) {
                console.log("项目变更？", this.isProjectChanged);
                if (this.selectedProject) {
                    // 判断是否有需求文档
                    if (this.selectedProject.documents && this.selectedProject.documents.length > 0) {
                        this.currentStep++;
                        if (this.isProjectChanged) {
                            this.fetchAnalysis(this.selectedProject);
                            this.isProjectChanged = false;
                        }
                    } else {
                        alert("选中的项目没有需求文档，无法进行需求分析。");
                    }
                } else {
                    alert("请先选择一个项目");
                }
            }
            else if (this.currentStep === 1) {
                console.log("第三步：测试分析");
                console.log("需求文本变更？", this.isReqTextChanged);
                console.log("需求建议变更？", this.isReqSuggestionChanged);
                if (this.selectedProject) {
                    // 判断是否有需求文档
                    if (this.selectedProject.documents && this.editedDocumentContent.length > 0) {
                        this.currentStep++;
                        if (this.isReqTextChanged || this.isReqSuggestionChanged) {
                            this.fetchTestAnalysis(this.selectedProject);
                            this.isReqTextChanged = false;
                            this.isReqSuggestionChanged = false;
                        }

                    } else {
                        alert("内部错误：选中的项目没有需求文档，无法进行测试分析。");
                    }
                } else {
                    alert("请先选择一个项目");
                }
            }
            else if (this.currentStep === 2) {
                console.log("第四步：测试用例生成");
                console.log("测试点变更？", this.isTestPointsChanged);
                if (this.selectedProject) {
                    // 判断是否有需求文档
                    if (this.selectedProject.documents && this.editedDocumentContent.length > 0 && this.editedTestAnalysisContent.length > 0) {
                        this.currentStep++;
                        if (this.isTestPointsChanged) {

                            this.fetchTestcaseAnalysis(this.selectedProject);
                            this.isTestPointsChanged = false;
                        }
                    } else {
                        alert("没有接受的测试点，无法进行测试用例生成。");
                    }
                } else {
                    alert("请先选择一个项目");
                }
            }
            else if (this.currentStep === 3) {
                console.log("第五步：结果概览");
                if (this.selectedProject) {
                    // 判断是否有需求文档
                    if (this.selectedProject.documents && this.editedDocumentContent.length > 0 && this.editedTestAnalysisContent.length > 0) {
                        this.currentStep++;
                    } else {
                        alert("没有接受的测试用例！");
                    }
                } else {
                    alert("请先选择一个项目");
                }
            }
            else if (this.currentStep < this.steps.length - 1) {
                this.currentStep++;
            }
        },
        goToPreviousStep() {
            if (this.currentStep > 0) {
                this.currentStep--;
            }
        },
        /*需求分析*/
        //首次获取需求分析结果
        async fetchAnalysis(project) {
            this.isLoading = true;
            this.lastAnalysisFunction = 'fetchAnalysis';
            this.analysisResult = [];
            this.overallAnalysis = '';

            try {
                const response = await axios.get(`/api/projects/${project._id}/analysis`, {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                });

                if (response.data && response.data.analysisItems && response.data.analysisItems.length > 0) {
                    //this.analysisResult = response.data.analysisItems;
                    this.analysisResult = response.data.analysisItems.map(item => ({
                        requirement: item.requirement,
                        suggestions: item.suggestion.split(/[0-9]+\./).filter(Boolean),// 用正则拆解建议
                        rejectedMap: {}  // 初始化 rejectedMap 
                    }));
                    this.overallAnalysis = response.data.overallAnalysis;
                    this.editedDocumentContent = response.data.docText;
                    if (this.overallAnalysis) {
                        this.handleError(this.overallAnalysis);
                    }
                } else {
                    this.handleError('需求分析异常，请重试');
                }
            } catch (error) {
                this.handleError('需求分析异常，请重试。错误详情：${error}');
                console.error('需求分析异常:', error);
            } finally {
                this.isLoading = false;
            }
        },
        //再次进行需求分析
        async sendForAnalysis() {
            this.isLoading = true;
            this.lastAnalysisFunction = 'sendForAnalysis';
            this.errorMessage = '';
            const formattedData = this.formatAcceptedItems();

            try {
                const response = await axios.post(`/api/projects/${this.selectedProject._id}/continue-analysis`, {
                    documentContent: this.editedDocumentContent,
                    command: this.analysisCommand,
                    analysisItems: formattedData.length ? formattedData : [],
                }, {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                });

                if (response.data && response.data.analysisItems && response.data.analysisItems.length > 0) {
                    //this.analysisResult = response.data.analysisItems;
                    this.analysisResult = response.data.analysisItems.map(item => ({
                        requirement: item.requirement,
                        suggestions: item.suggestion.split(/[0-9]+\./).filter(Boolean),// 用正则拆解建议
                        rejectedMap: {}  // 初始化 rejectedMap 
                    }));
                    this.overallAnalysis = response.data.overallAnalysis;
                    if (this.overallAnalysis) {
                        this.handleError(this.overallAnalysis);
                    }
                } else {
                    this.handleError('需求分析异常，请重试');
                }
            } catch (error) {
                this.handleError('需求分析异常，请重试。错误详情：${error}');
                console.error('需求分析异常:', error);
            } finally {
                this.isLoading = false;
            }
        },
        //需求分析结果操作按钮
        acceptReqItem(item) {
            const newItem = { ...item, id: uuidv4() }; // 新的唯一ID
            this.acceptedReqItems.push(newItem);

            this.analysisResult = this.analysisResult.map(cat => {
                if (cat.requirement === item.requirement) {
                    return {
                        ...cat,
                        suggestions: cat.suggestions.filter(suggestion => suggestion !== item.suggestion),
                        rejectedMap: {
                            ...cat.rejectedMap,
                            [item.suggestion]: false  // 确保从rejectedMap中移除
                        }
                    };
                }
                return cat;
            }).filter(cat => cat.suggestions.length > 0 || Object.values(cat.rejectedMap).some(v => v));
        },
        rejectReqItem(item) {
            this.analysisResult = this.analysisResult.map(cat => {
                if (cat.requirement === item.requirement) {
                    return {
                        ...cat,
                        rejectedMap: {
                            ...cat.rejectedMap,
                            [item.suggestion]: true
                        }
                    };
                }
                return cat;
            });
        },
        removeReqItem(id) {
            // 根据唯一标识符删除具体项
            this.acceptedReqItems = this.acceptedReqItems.filter(item => item.id !== id);
        },
        groupByRequirement(items) {
            // 使用 reduce 方法来分组数据
            return items.reduce((acc, item) => {
                if (!acc[item.requirement]) {
                    acc[item.requirement] = [];
                }
                acc[item.requirement].push(item);
                return acc;
            }, {});
        },
        formatAcceptedItems() {
            this.formattedItems = [];
            const groupedItems = this.groupByRequirement(this.acceptedReqItems);

            for (const [requirement, items] of Object.entries(groupedItems)) {
                const suggestions = items.map(item => item.suggestion).join(' ');
                this.formattedItems.push({
                    requirement,
                    suggestions,
                });
            }

            return this.formattedItems;
        },
        /*测试分析*/
        async fetchTestAnalysis(project) {
            this.isLoading = true;
            this.lastTestAnalysisFunction = 'fetchTestAnalysis';
            this.testAnalysisResult = [];
            this.overallTestAnalysis = '';
            console.log("fetching test analysis result");

            try {
                const response = await axios.post(`/api/projects/${project._id}/test-analysis`, {
                    documentContent: this.editedDocumentContent,
                }, {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                });

                console.log("fetched test analysis result");

                if (response.data && response.data.analysisItems && response.data.analysisItems.length > 0) {
                    this.testAnalysisResult = response.data.analysisItems[0].suggestion;
                    this.overallTestAnalysis = response.data.overallAnalysis;
                    this.editedTestAnalysisContent = this.testAnalysisResult.split('\n').map((item, index) => `测试点${index + 1}：${item}`).join('\n');
                    this.testPoints = this.editedTestAnalysisContent.split('\n').filter(line => line.startsWith('测试点')).map(line => line.replace(/测试点\d+：\s*/, ''));
                    console.log("测试分析结果：", this.testAnalysisResult);
                    console.log("拆分后的测试分析结果：", this.editedTestAnalysisContent);
                    console.log("测试点：", this.testPoints);
                    if (this.overallTestAnalysis) {
                        this.handleError(this.overallTestAnalysis);
                    }
                } else {
                    this.handleError('测试分析异常，请重试');
                }
            } catch (error) {
                this.handleError('测试分析异常，请重试。错误详情：${error}');
                console.error('测试分析异常:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async sendForTestAnalysis() {
            this.isLoading = true;
            this.lastTestAnalysisFunction = 'sendForTestAnalysis';
            this.errorMessage = '';
            console.log("sending for test analysis result");

            try {
                // 将 editedTestAnalysisContent 转换回 JSON 格式
                //const formattedTestAnalysisContent = this.editedTestAnalysisContent.replace(/\n/g, ' ');
                this.formattedTestAnalysisContent = this.testPoints.join('\n');
                console.log("上传的测试点：", this.formattedTestAnalysisContent);

                const response = await axios.post(`/api/projects/${this.selectedProject._id}/continue-test-analysis`, {
                    documentContent: this.editedDocumentContent,
                    testAnalysisText: this.formattedTestAnalysisContent,
                    command: this.testAnalysisCommand
                }, {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                });

                console.log("got test analysis result");

                if (response.data && response.data.analysisItems && response.data.analysisItems.length > 0) {
                    this.testAnalysisResult = response.data.analysisItems[0].suggestion;
                    this.overallTestAnalysis = response.data.overallAnalysis; this.editedTestAnalysisContent = this.testAnalysisResult.split('\n').map((item, index) => `测试点${index + 1}：${item}`).join('\n');
                    this.testPoints = this.editedTestAnalysisContent.split('\n').filter(line => line.startsWith('测试点')).map(line => line.replace(/测试点\d+：\s*/, ''));
                    console.log("测试分析结果：", this.testAnalysisResult);
                    console.log("拆分后的测试分析结果：", this.editedTestAnalysisContent);
                    console.log("测试点：", this.testPoints);
                    if (this.overallTestAnalysis) {
                        this.handleError(this.overallTestAnalysis);
                    }
                } else {
                    this.handleError('测试分析异常，请重试');
                }
            } catch (error) {
                this.handleError('测试分析异常，请重试。错误详情：${error}');
                console.error('测试分析异常:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async fetchTestcaseAnalysis(project) {
            this.isLoading = true;
            this.lastTestcaseAnalysisFunction = 'fetchTestcaseAnalysis';
            this.testcaseAnalysisResult = [];
            this.overallTestcaseAnalysis = '';
            // 将testPoints数组转换回editedTestAnalysisContent字符串
            this.editedTestAnalysisContent = this.testPoints.map((point, index) => `测试点${index + 1}：${point}`).join('\n');


            try {
                const response = await axios.post(`/api/projects/${project._id}/test-case-analysis`, {
                    documentContent: this.editedDocumentContent,
                    testAnalysisContent: this.editedTestAnalysisContent,
                }, {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                });

                if (response.data && response.data.analysisItems && response.data.analysisItems.length > 0) {
                    this.testcaseAnalysisResult = response.data.analysisItems;
                    console.log("返回的测试用例：", this.testcaseAnalysisResult);
                    this.overallTestcaseAnalysis = response.data.overallAnalysis;
                    this.editedTestcaseAnalysisContent = response.data.overallAnalysis;
                    if (this.overallTestcaseAnalysis) {
                        this.handleError(this.overallTestcaseAnalysis);
                    }
                } else {
                    this.handleError('测试用例生成失败，请重试');
                }
            } catch (error) {
                this.handleError('测试用例生成失败，请重试。错误详情：${error}');
                console.error('测试用例生成失败:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async sendForTestcaseAnalysis() {
            this.isLoading = true;
            this.lastTestcaseAnalysisFunction = 'sendForTestcaseAnalysis';
            this.errorMessage = '';
            this.testcaseAnalysisResult = [];
            // 将testPoints数组转换回editedTestAnalysisContent字符串
            this.editedTestAnalysisContent = this.testPoints.map((point, index) => `测试点${index + 1}：${point}`).join('\n');


            try {
                const response = await axios.post(`/api/projects/${this.selectedProject._id}/continue-test-case-analysis`, {
                    documentContent: this.editedDocumentContent,
                    testAnalysisContent: this.editedTestAnalysisContent,
                    testCaseText: this.acceptedTestcases,
                    command: this.testcaseAnalysisCommand
                }, {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
                });

                if (response.data && response.data.analysisItems && response.data.analysisItems.length > 0) {
                    this.testcaseAnalysisResult = response.data.analysisItems;
                    this.overallTestcaseAnalysis = response.data.overallAnalysis;
                    if (this.overallTestcaseAnalysis) {
                        this.handleError(this.overallTestcaseAnalysis);
                    }
                } else {
                    this.handleError("测试用例生成失败，请重试");
                }
            } catch (error) {
                this.handleError('测试用例生成失败，请重试。错误详情：${error}');
                console.error('测试用例生成失败:', error);
            } finally {
                this.isLoading = false;
            }
        },

        handleError(message) {
            this.errorMessage = message;
        },
        retryFetchAnalysis() {
            if (this.lastAnalysisFunction === 'fetchAnalysis') {
                this.fetchAnalysis(this.selectedProject);
                console.log("fetchAnalysis");
            } else if (this.lastAnalysisFunction === 'sendForAnalysis') {
                this.sendForAnalysis();
                console.log("sendForAnalysis");
            } else {
                console.error('未知的上一次需求分析函数调用');
            }
        },
        retryFetchTestAnalysis() {
            if (this.lastTestAnalysisFunction === 'fetchTestAnalysis') {
                this.fetchTestAnalysis(this.selectedProject);
                console.log("fetchTestAnalysis");
            } else if (this.lastTestAnalysisFunction === 'sendForTestAnalysis') {
                this.sendForTestAnalysis();
                console.log("sendForTestAnalysis");
            } else {
                console.error('未知的上一次测试分析函数调用');
            }
        },
        retryFetchTestcaseAnalysis() {
            if (this.lastTestcaseAnalysisFunction === 'fetchTestcaseAnalysis') {
                this.fetchTestcaseAnalysis(this.selectedProject);
                console.log("fetchTestcaseAnalysis");
            } else if (this.lastTestcaseAnalysisFunction === 'sendForTestcaseAnalysis') {
                this.sendForTestcaseAnalysis();
                console.log("sendForTestcaseAnalysis");
            } else {
                console.error('未知的上一次测试设计函数调用');
            }
        },
        // 全部接受
        acceptAll() {
            this.analysisResult.forEach(item => {
                item.accepted = true;
            });
        },
        // 全部拒绝
        rejectAll() {
            this.analysisResult.forEach(item => {
                item.accepted = false;
            });
        },
        // 单独确认、拒绝的按钮
        confirmItem(index) {
            this.analysisResult[index].accepted = true;
        },
        rejectItem(index) {
            this.analysisResult[index].accepted = false;
        },
        editField(index, fieldKey) {
            this.editIndex = index;
            this.editFieldKey = fieldKey;
        },
        saveField() {
            this.editIndex = null;
            this.editFieldKey = null;
        },
        confirmTestcaseItem(index) {
            // 保存逻辑
            this.testcaseAnalysisResult[index].accepted = true;
            const item = this.testcaseAnalysisResult[index];
            this.acceptedTestcases.push(item);
            //this.testcaseAnalysisResult.splice(index, 1);
        },
        rejectTestcaseItem(index) {
            // 丢弃逻辑
            this.testcaseAnalysisResult[index].accepted = false;
            //this.testcaseAnalysisResult.splice(index, 1);
        },
        acceptAllTestcases() {
            this.testcaseAnalysisResult.forEach(item => item.accepted = true);
            this.acceptedTestcases.push(...this.testcaseAnalysisResult);
        },
        rejectAllTestcases() {
            this.testcaseAnalysisResult.forEach(item => item.accepted = false);
            //this.testcaseAnalysisResult = [];
        },
        editAcceptedField(index, fieldKey) {
            this.acceptedEditIndex = index;
            this.acceptedEditFieldKey = fieldKey;
        },
        saveAcceptedField() {
            this.acceptedEditIndex = null;
            this.acceptedEditFieldKey = null;
        },
        removeAcceptedTestcase(index) {
            this.acceptedTestcases.splice(index, 1);
        },
        async exportToWord() {
            try {
                const doc = new Document({
                    creator: "x-test user", // 添加 creator 属性
                    title: "功能测试用例",
                    description: "导出的功能测试用例清单",
                    sections: [
                        {
                            properties: {},
                            children: [
                                new Paragraph({
                                    text: "需求文本",
                                    heading: HeadingLevel.HEADING_1 // 设置为标题1格式
                                }),
                                new Paragraph({
                                    children: [
                                        new TextRun({
                                            text: this.editedDocumentContent,
                                            bold: false // 加粗文本
                                        })
                                    ]
                                })
                            ]
                        }
                    ]
                });

                // 添加需求修改表格
                const tableRows = [];
                for (const requirement in this.groupedAcceptedItems) {
                    const group = this.groupedAcceptedItems[requirement];
                    group.forEach((item, index) => {
                        const cells = [
                            new TableCell({
                                children: [new Paragraph(requirement)],
                                verticalMerge: index === 0 ? "restart" : "continue",
                                margins: {
                                    top: 100, bottom: 100, left: 100, right: 100
                                }
                            }),
                            new TableCell({
                                children: [new Paragraph(item.suggestion)]
                            })
                        ];
                        tableRows.push(new TableRow({ children: cells }));
                    });
                }


                doc.addSection({
                    children: [
                        new Paragraph({
                            text: "需求修改建议",
                            heading: HeadingLevel.HEADING_1 // 设置为标题1格式
                        }),
                        new Table({
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({
                                            children: [new Paragraph("建议类型")]
                                        }),
                                        new TableCell({
                                            children: [new Paragraph("建议")]
                                        })
                                    ]
                                }),
                                ...tableRows
                            ]
                        })
                    ]
                });

                // 添加测试点表格
                const testPointRows = this.testPoints.map((point, index) => (
                    new TableRow({
                        children: [
                            new TableCell({ children: [new Paragraph(`测试点${index + 1}`)] }),
                            new TableCell({ children: [new Paragraph(point)] })
                        ]
                    })
                ));

                doc.addSection({
                    children: [
                        new Paragraph({
                            text: "测试点",
                            heading: HeadingLevel.HEADING_1
                        }),
                        new Table({
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({ children: [new Paragraph("测试点")] }),
                                        new TableCell({ children: [new Paragraph("内容")] })
                                    ]
                                }),
                                ...testPointRows
                            ]
                        })
                    ]
                });

                console.log("导出测试点");
                // 添加测试用例表格
                const testcaseRows = this.acceptedTestcases.map(item => (
                    new TableRow({
                        children: [
                            new TableCell({ children: [new Paragraph(item.title)] }),
                            new TableCell({ children: [new Paragraph(item.designMethod)] }),
                            new TableCell({ children: [new Paragraph(item.preCondition)] }),
                            new TableCell({ children: [new Paragraph(item.steps)] }),
                            new TableCell({ children: [new Paragraph(item.expectedResult)] })
                        ]
                    })
                ));

                doc.addSection({
                    children: [
                        new Paragraph({
                            text: "测试用例",
                            heading: HeadingLevel.HEADING_1 // 设置为标题1格式
                        }),
                        new Table({
                            rows: [
                                new TableRow({
                                    children: [
                                        new TableCell({ children: [new Paragraph("用例标题")] }),
                                        new TableCell({ children: [new Paragraph("设计方法")] }),
                                        new TableCell({ children: [new Paragraph("前置条件")] }),
                                        new TableCell({ children: [new Paragraph("测试步骤")] }),
                                        new TableCell({ children: [new Paragraph("预期结果")] })
                                    ]
                                }),
                                ...testcaseRows
                            ]
                        })
                    ]
                });
                console.log("导出测试用例表格");
                const blob = await Packer.toBlob(doc);
                saveAs(blob, "项目分析结果.docx");
            }
            catch (error) {
                console.error("导出Word文件时出错：", error);
                console.error("错误详情：", error.message, error.stack);
            }
        },
        computed: {
            groupedAcceptedItems() {
                return this.acceptedReqItems.reduce((groups, item) => {
                    const group = groups[item.requirement] || (groups[item.requirement] = []);
                    group.push(item);
                    return groups;
                }, {});
            },
        }
    },
    mounted() {
        // 初始化可调整宽度的表格
        $(this.$el).find('.resizable').resizableColumns();
    }
};
</script>
<style scoped>
.functional-test {
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.step-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
    /* 可换行适应屏幕宽度 */
}

.step {
    flex: 1;
    min-width: 120px;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 30px;
    /* 增加格子之间的间距 */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    transition: background-color 0.3s;
    position: relative;
}

.step:last-child {
    margin-right: 0;
    /* 去掉最后一个格子的右边距 */
}

.step:not(:last-child)::after {
    content: '→';
    position: absolute;
    right: -25px;
    /* 调整箭头位置 */
    top: 50%;
    transform: translateY(-50%);
    color: #3498db;
}

.step.active {
    background-color: #3498db;
    color: white;
}

.divider {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 10px 0;
}

.upload-section,
.analysis-section,
.test-case-section {
    width: 100%;
    margin-top: 20px;
}

.analysis-section table {
    width: 100%;
    border-collapse: collapse;
}

.analysis-section th,
.analysis-section td {
    border: 1px solid #ddd;
    padding: 10px;
}

/* 需求编辑框样式 */
.editor-section {
    width: 100%;
    margin-top: 0px;
}

.editor {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    /* 确保宽度包括内边距和边框 */
}

.command-input {
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    /* 确保宽度包括内边距和边框 */
}

.analyze-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    /* 防止按钮文字换行 */
}

.analyze-button:hover {
    background-color: #218838;
}

button {
    padding: 5px 10px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #2980b9;
}

.test-case {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.search-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.search-box {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex-grow: 1;
    width: 200px;
    /* 调整搜索框的宽度 */
    box-sizing: border-box;
    /* 确保内边距和边框包含在宽度内 */
    margin-bottom: 0px;
}

.search-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    /* 防止按钮文字换行 */
    margin-right: 10px;
    /* 增加按钮之间的间距 */
    display: flex;
    align-items: center;
    /* 垂直居中 */
    height: 40px;
    /* 设置与搜索框相同的高度 */
    margin-bottom: 10px;
}

.new-project-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    /* 防止按钮文字换行 */
    display: flex;
    align-items: center;
    /* 垂直居中 */
    height: 40px;
    /* 设置与搜索框相同的高度 */
    margin-bottom: 10px;
}

.search-btn:hover {
    background-color: #0056b3;
}

.new-project-btn:hover {
    background-color: #0056b3;
}

.analysis-section {
    position: relative;
    /* 使子元素的定位基于此容器 */
    padding-bottom: 60px;
    /* 确保内容不会被按钮覆盖 */
}

.button-container {
    display: flex;
    justify-content: space-between;
    /* 按钮两端对齐 */
    margin-top: 30px;
    /* 增加顶部间距 */
    position: relative;
    /* 固定按钮位置 */
    bottom: 20px;
    /* 距离底部的距离 */
    left: 0px;
    right: 0px;
    padding: 0 0px;
    /* 增加左右内边距 */
    width: 100%;
    box-sizing: border-box;
    /* 确保宽度包括内边距和边框 */
}

.req-result-section {
    position: relative;
    /* 使子元素的定位基于此容器 */
    padding-top: 0px;
    /* 确保内容不会被按钮覆盖 */
}

.accrej-all-container {
    display: flex;
    justify-content: right;
    /* 按钮两端对齐 */
    margin-top: 0px;
    /* 增加顶部间距 */
    position: relative;
    /* 距离顶部的距离 */
    top: 0px;
    /* 距离底部的距离 */
    left: 0px;
    right: 0px;
    padding: 0 0px;
    /* 增加左右内边距 */
    width: 100%;
    box-sizing: border-box;
}

.next-step-btn-container {
    margin-left: auto;
    /* right: 0px;
     距离右边的距离 */
}

.previous-step-btn-container {
    margin-right: auto;
    /* left: 0px;
    距离左边的距离 */
}

.accept-all-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    /* 防止按钮文字换行 */
    display: flex;
    align-items: center;
    /* 垂直居中 */
    height: 40px;
    margin-right: 10px;
}

.accept-all-btn:hover {
    background-color: #0056b3;
}

.reject-all-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    /* 防止按钮文字换行 */
    display: flex;
    align-items: center;
    /* 垂直居中 */
    height: 40px;
}

.reject-all-btn:hover {
    background-color: #0056b3;
}

.next-step-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    /* 防止按钮文字换行 */
    display: flex;
    align-items: center;
    /* 垂直居中 */
    height: 40px;
    /* 设置与搜索框相同的高度 */
}

.next-step-btn:hover {
    background-color: #0056b3;
}

.previous-step-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    /* 防止按钮文字换行 */
    display: flex;
    align-items: center;
    /* 垂直居中 */
    height: 40px;
    /* 设置与搜索框相同的高度 */
}

.previous-step-btn:hover {
    background-color: #0056b3;
}

.project-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}


.project-table th,
.project-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.project-table th {
    background-color: #f2f2f2;
}

.project-table tbody tr:hover {
    background-color: #f1f1f1;
}

/* 新增样式 */
.project-table .filename-column {
    max-width: 200px;
    /* 设置最大宽度 */
    white-space: nowrap;
    /* 不换行 */
    overflow: hidden;
    /* 溢出隐藏 */
    text-overflow: ellipsis;
    /* 溢出用省略号表示 */
}

.ReqAnaRes {
    margin-top: 0px;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.ReqAnaRes th,
.ReqAnaRes td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.ReqAnaRes th {
    background-color: #f2f2f2;
}

.ReqAnaRes tbody tr:hover {
    background-color: #f1f1f1;
}

.rejected {
    text-decoration: line-through;
}

.editor-input {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    font-size: 14px;
    border: none;
    /* 按需调整 */
}

.TestcaseAnaRes {
    margin-top: 5px;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table.TestcaseAnaRes th,
table.TestcaseAnaRes td {
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: top;
    /* 使文本顶部对齐 */
    word-break: break-word;
    /* 允许单词换行 */
}

table.TestcaseAnaRes th {
    background-color: #f2f2f2;
    text-align: left;
}

/* 设置每一列的固定宽度 */
table.TestcaseAnaRes th:nth-child(1),
table.TestcaseAnaRes td:nth-child(1) {
    width: 15%;
    /* 用例标题列 */
}

table.TestcaseAnaRes th:nth-child(2),
table.TestcaseAnaRes td:nth-child(2) {
    width: 10%;
    /* 设计方法列 */
}

table.TestcaseAnaRes th:nth-child(3),
table.TestcaseAnaRes td:nth-child(3) {
    width: 15%;
    /* 前置条件列 */
}

table.TestcaseAnaRes th:nth-child(4),
table.TestcaseAnaRes td:nth-child(4) {
    width: 35%;
    /* 测试步骤列 */
}

table.TestcaseAnaRes th:nth-child(5),
table.TestcaseAnaRes td:nth-child(5) {
    width: 20%;
    /* 预期结果列 */
}

table.TestcaseAnaRes th:nth-child(6),
table.TestcaseAnaRes td:nth-child(6) {
    width: 5%;
    /* 操作列 */
}

.edit-input,
.edit-textarea {
    width: 100%;
    /*height: 100%;*/
    box-sizing: border-box;
    /* 包括内边距和边框在内的完整大小 */
    padding: 8px;
    /* 移除默认的内边距 */
    font-size: 14px;
    margin: 0;
    /* 移除默认的外边距 */
    border: none;
    /* 移除默认的边框 */
    outline: none;
    /* 移除默认的聚焦样式 */
    resize: none;
    /* 禁止调整大小 */
}

td {
    white-space: pre-wrap;
    /* 保留空白符并允许换行 */
}

.TestcaseAnaRes th {
    background-color: #f2f2f2;
}

.TestcaseAnaRes tbody tr:hover {
    background-color: #f1f1f1;
}


.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
}

.close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
select,
input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #218838;
}

.progress-bar {
    height: 20px;
    background: #3498db;
    width: 0;
    transition: width 0.3s;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    /* 显示“禁止”图标 */
}



.loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #09f;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error-message {
    color: red;
    margin-top: 20px;
}


.fixed-width-table {
    width: 100%;
    border-collapse: collapse;
}

.fixed-width-table th,
.fixed-width-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.fixed-width-table th {
    background-color: #f2f2f2;
}

.requirement-column {
    width: 15%;
}

.suggestion-column {
    width: 80%;
}

.test-points-table {
    width: 100%;
    border-collapse: collapse;
}

.test-points-table th,
.test-points-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.test-points-table th {
    background-color: #f2f2f2;
}

.test-point-column {
    width: 15%;
}

.test-point-content-column {
    width: 80%;
}

.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
}

.editor-readonly {
    width: 100%;
    height: 200px;
    /* 固定高度 */
    padding: 8px;
    border: 1px solid #ddd;
    overflow-y: auto;
    /* 垂直滚动 */
    white-space: pre-wrap;
    /* 保留空白符并允许换行 */
    word-wrap: break-word;
    /* 允许单词换行 */
    background-color: #f9f9f9;
    /* 背景颜色 */
    box-sizing: border-box;
    /* 包括内边距和边框在内的完整大小 */
}
</style>
