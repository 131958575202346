<template>
  <div class="dock">
    <ul>
      <li><router-link to="/" class="dock-item" active-class="active">首页</router-link></li>
      <li><router-link to="/projects" class="dock-item" active-class="active">我的项目</router-link></li>
      <li><router-link to="/documents" class="dock-item" active-class="active">我的文档</router-link></li>
      <li><router-link to="/knowledge-base" class="dock-item" active-class="active">业务知识库</router-link></li>
      <li><router-link to="/test-cases" class="dock-item" active-class="active">测试用例库</router-link></li>
      <li><router-link to="/overview" class="dock-item" active-class="active">概览</router-link></li>
    </ul>
    <div class="personal-info">
      <router-link to="/personal-info" class="dock-item" active-class="active">个人信息</router-link>
    </div>
  </div>
</template>

<style scoped>
:root {
  --dock-width: 220px; /* 定义dock栏的宽度 */
  --dock-bg-color: #2c3e50;
  --dock-item-color: #ecf0f1;
  --dock-item-hover-bg-color: #34495e;
  --dock-item-active-bg-color: #2980b9;
  --dock-item-active-color: #fff;
}

.dock {
  width: var(--dock-width);
  height: 100vh;
  background-color: #f7f9fb;
  color: #070113;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 10px 0;
}

.dock-item {
  display: block;
  padding: 10px 15px;
  color: var(--dock-item-color);
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.dock-item:hover {
  background-color: #90c1f3;
  transform: scale(1.05);
}

.dock-item.active {
  background-color: #2980b9;
  color:#fff;
  transform: scale(1.1);
}

.personal-info {
  margin-top: auto;
  margin-bottom: 30px;
}

.personal-info .dock-item {
  margin-top: 20px;
}

.library-detail-container {
  padding: 20px;
  flex-grow: 1; /* 使内容区域占满剩余空间 */
}

.library-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

</style>
