<template>
  <div class="home">
    <h2>应用</h2>
    <hr class="divider" />
    <div class="image-links">
      <ImageLink
        v-for="(item, index) in imageLinkData"
        :key="index"
        :imageSrc="require(`@/assets/${item.imageSrc}`)"
        :altText="item.altText"
        :link="item.link"
        :linkText="item.linkText"
      />
    </div>
  </div>
</template>

<script>
import ImageLink from './ImageLink.vue'

export default {
  name: 'Home',
  components: {
    ImageLink
  },
  data() {
    return {
      imageLinkData: [
        { imageSrc: 'image1.jpg', altText: '功能测试', link: '/functional-test', linkText: '功能测试' },
        { imageSrc: 'image2.jpg', altText: '性能测试', link: '/performance-test', linkText: '性能测试' },
        { imageSrc: 'image3.jpg', altText: '可靠性测试', link: '/reliability-test', linkText: '可靠性测试' },
        { imageSrc: 'image4.jpg', altText: '可靠性分析', link: '/reliability-analysis', linkText: '可靠性分析' },
        { imageSrc: 'image5.jpg', altText: '安全性分析', link: '/safety-analysis', linkText: '安全性分析' }
      ]
    }
  }
}
</script>

<style scoped>
.home {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

h2 {
  margin: 0;
  font-size: 24px;
}

.divider {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.image-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 60px;
  width: 100%;
  justify-items: center;
  flex-grow: 1;
  align-content: start; /* 确保内容从顶部开始排列 */
}

.image-link-wrapper {
  text-decoration: none; /* 移除默认链接样式 */
  color: inherit; /* 继承颜色 */
}

.image-link-wrapper > * {
  border: none; /* 移除边框 */
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 50px;
}
</style>
