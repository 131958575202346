import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/Home.vue'
import Projects from './views/Projects.vue'
import Documents from './views/Documents.vue'
import KnowledgeBase from './views/KnowledgeBase.vue'
import KnowledgeBaseDetail from './views/KnowledgeBaseDetail.vue' // 新增
import EditSlice from './views/EditSlice.vue' // 新增
import TestCases from './views/TestCases.vue'
import LibraryDetail from './views/LibraryDetail.vue'
import Overview from './views/Overview.vue'
import Login from './views/Login.vue'; // 新增
import Register from './views/Register.vue';
import PersonalInfo from './views/PersonalInfo.vue';
import FunctionalTest from './views/FunctionalTest.vue'
import PerformanceTest from './views/PerformanceTest.vue'
import ReliabilityTest from './views/ReliabilityTest.vue'
import ReliabilityAnalysis from './views/ReliabilityAnalysis.vue'
import SafetyAnalysis from './views/SafetyAnalysis.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/projects', component: Projects },
  { path: '/documents', component: Documents },
  { path: '/knowledge-base', component: KnowledgeBase },
  { path: '/knowledge-base/:id', name: 'KnowledgeBaseDetail', component: KnowledgeBaseDetail }, // 新增知识库详情路由
  { path: '/edit-slice/:id', component: EditSlice }, // 新增切片编辑路由
  { path: '/test-cases', component: TestCases },
  { path: '/test-cases/:id', name: 'LibraryDetail', component: LibraryDetail }, // 新增用例库详情路由
  { path: '/overview', component: Overview },
  { path: '/personal-info', component: PersonalInfo }, // 个人信息页面暂时使用 Home 组件
  { path: '/login', component: Login }, // 新增
  { path: '/register', component: Register }, // 新增
  {
    path: '/functional-test',
    name: 'FunctionalTest',
    component: FunctionalTest
  },
  {
    path: '/performance-test',
    name: 'PerformanceTest',
    component: PerformanceTest
  },
  {
    path: '/reliability-test',
    name: 'ReliabilityTest',
    component: ReliabilityTest
  },
  {
    path: '/reliability-analysis',
    name: 'ReliabilityAnalysis',
    component: ReliabilityAnalysis
  },
  {
    path: '/safety-analysis',
    name: 'SafetyAnalysis',
    component: SafetyAnalysis
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token');

  console.log('Navigation Guard Triggered');
  console.log('authRequired:', authRequired);
  console.log('loggedIn:', loggedIn);
  
  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});


const app = createApp(App)
app.use(router)
console.log('Mounting app');
app.mount('#app')
console.log('App mounted');
