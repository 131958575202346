<template>
  <div>
    <h2>概览视图</h2>
    <p>项目、文档、需求、用例概览</p>
  </div>
</template>

<script>
export default {
  name: 'Overview'
}
</script>

<style scoped>
h2 {
  margin: 0;
  font-size: 24px;
}
</style>
